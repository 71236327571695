import React from 'react';
import 'react-phone-number-input/style.css';
// import {apiUrl} from '../../common/Globals';
// import {HTTP_GET} from '../../common/HttpService';
import {getServiceCategory} from '../../dataproviders/ServiceCategory';
import {
  Create,
  FormTab,
  TabbedForm,
  //   TextField,
  TextInput,
  FormDataConsumer,
  // AutocompleteInput,
  ReferenceInput,
  ImageField,
  ImageInput,
  SelectInput,
  required,
  CardActions
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';
export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const bannerTypeList = [
  {id: 'customer', name: 'customer'},
  {id: 'vendor', name: 'vendor'}
];

const BannerCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Banner" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Banner Details" path="">
        <TextInput
          autoFocus
          source="bannerName"
          label="Banner Name"
          validate={required()}
          formClassName={classes.banner_name}
        />
        <ReferenceInput
          source="serviceCategory"
          label="Service Category"
          reference="serviceCategory"
          validate={[required()]}
        >
          <SelectInput optionText="serviceCategoryName" />
        </ReferenceInput>
        <SelectInput source="bannerType" choices={bannerTypeList} />
        <FormDataConsumer>
          {({formData}) => {
            console.log(formData);
            if (formData.serviceCategoryId !== undefined) {
              var t = formData.serviceCategoryId;
              console.log('service category Id--------->', t);
              getServiceCategory({id: t}).then(function (response) {
                console.log('!!!!!');
                console.log(response.data.serviceCategoryName);
                formData.serviceCategoryName = response.data.serviceCategoryName;
              });
            }
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Images">
        <ImageField source="imagePath" title="imagePath" />
        {/* <ImageField source="imagePath" src="imagePath" label="Service image" /> */}
        <ImageInput
          source="images"
          label="images"
          accept="image/*"
          maxSize={5000000}
          placeholder={
            <p>
              Upload Image
              <span>*File size should not exceed 5MB</span>
            </p>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(BannerCreate);
