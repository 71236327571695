import React from 'react';
import {List, Datagrid, TextField, Filter, TextInput, DateInput} from 'react-admin';
// import withStyles from '@material-ui/core/styles/withStyles';
const UserFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput source="customerName" label="Customer Name" alwaysOn />
    <TextInput source="customerId" label="Customer Id" />
    <TextInput source="vendorName" label="Vendor Name" alwaysOn />
    <TextInput source="vendorId" label="Vendor Id" />
    <TextInput source="serviceName" label="Service Name" alwaysOn />
    <TextInput source="userType" label="User Type" alwaysOn />
    <TextInput source="serviceCategory" label="Service Category" alwaysOn />
    <TextInput source="entryType" label="Entry Type" />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

export const ServiceRequestList = (props) => (
  <List title=" ServiceRequest" {...props} filters={<UserFilter />}>
    <Datagrid rowClick={rowClick} {...props}>
      <TextField source="customerName" label="Customer Name" />
      <TextField source="customerId" label="Customer Id" />
      <TextField source="vendorName" label="Vendor Name" />
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="serviceName" label="Service Name" />
      <TextField source="userType" label="User Type" />
      <TextField source="serviceCategory" label="Service Category" />
      <TextField source="entryType" label="Entry Type" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default ServiceRequestList;
