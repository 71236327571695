import React, {useState} from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  SelectInput
} from 'react-admin';
// import {withStyles} from '@material-ui/core';

const TruckEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
);

const currentStatusList = [
  {id: 'pending approval', name: 'pending approval'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'approved', name: 'approved'}
];

const TruckEdit = ({...props}) => {
  const [currentStatus, setCurrentStatus] = useState('');

  const handleStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };

  return (
    <Edit {...props}>
      <TabbedForm toolbar={<TruckEditToolbar />}>
        <FormTab label="Truck Details">
          <TextInput source="vendorId" label="Vendor Id" />
          <TextInput source="Type" label="Truck Type" />
          <TextInput source="rcownername" label="RC Owner Name" />
          <TextInput source="platenumber" label="Register Number" />
          <TextInput source="badge" label="Badge Number" />
          <TextInput source="volumecapacity" label="Vehicle Capacity" />
          <TextInput source="servicecharge" label="Service Charge (per Kilometer)" />
          <TextInput source="description" label="Description" />
          <SelectInput source="currentStatus" choices={currentStatusList} onChange={handleStatusChange} />

          {currentStatus === 'disqualified' && (
            <TextInput
              source="disqualifiedReason"
              multiline={true}
              numberOfLines={10}
              label="Reason for disqualification"
            />
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default TruckEdit;
