import UserDataProvider from './Users';
import AdminDataProvider from './Admins';
import ServiceDataProvider from './Service';
import ServiceCategoryDataProvider from './ServiceCategory';
import BannerDataProvider from './Banner';
import NotificationDataProvider from './Notification';
import CompanyDetailsDataProvider from './CompanyDetails';
import ManpowerRequestsDataProvider from './ManpowerRequests';
import ProjectRequestsDataProvider from './ProjectRequests';
import RentalRequestsDataProvider from './RentalRequests';
import TrainingRequestsDataProvider from './TrainingRequests';
import VendorDataProvider from './Vendors';
import ProjectSpecificationDataProvider from './ProjectSpecifications';
import UnitDataProvider from './Units';
import JobRequestRadiusDataProvider from './JobRequestRadius';
import ClientTransactionDataProvider from './Accounts';
import ClientTransactionSummaryDataProvider from './AccountsSummary';
import DashboardDataProvider from './Dashboard';
import ToolsDataProvider from './Tools';
import TaxiDataProvider from './Taxi';
import TruckDataProvider from './Truck';
import CourseDataProvider from './Course';
import ServiceRequestsDataProvider from './ServiceRequests';
import {RESOURCES} from '../common/Resources';

export const DataProvider = (type, resource, params) => {
  console.log('DataProvider Type', type);
  switch (resource) {
    case RESOURCES.USERS:
      return UserDataProvider(type, resource, params);

    case RESOURCES.ADMINS:
      return AdminDataProvider(type, resource, params);

    case RESOURCES.SERVICE:
      return ServiceDataProvider(type, resource, params);

    case RESOURCES.SERVICECATEGORY:
      return ServiceCategoryDataProvider(type, resource, params);
    case RESOURCES.BANNER:
      return BannerDataProvider(type, resource, params);
    case RESOURCES.NOTIFICATION:
      return NotificationDataProvider(type, resource, params);

    case RESOURCES.COMPANYDETAILS:
      return CompanyDetailsDataProvider(type, resource, params);

    case RESOURCES.MANPOWERREQUESTS:
      return ManpowerRequestsDataProvider(type, resource, params);

    case RESOURCES.VENDORS:
      return VendorDataProvider(type, resource, params);

    case RESOURCES.PROJECTSPECIFICATIONS:
      return ProjectSpecificationDataProvider(type, resource, params);

    case RESOURCES.UNITS:
      return UnitDataProvider(type, resource, params);

    case RESOURCES.JOBREQUESTRADIUS:
      return JobRequestRadiusDataProvider(type, resource, params);

    case RESOURCES.PROJECTREQUESTS:
      return ProjectRequestsDataProvider(type, resource, params);

    case RESOURCES.RENTALREQUESTS:
      return RentalRequestsDataProvider(type, resource, params);

    case RESOURCES.TRAININGREQUESTS:
      return TrainingRequestsDataProvider(type, resource, params);

    case RESOURCES.ACCOUNTS:
      return ClientTransactionDataProvider(type, resource, params);

    case RESOURCES.ACCOUNTS_SUMMARY:
      return ClientTransactionSummaryDataProvider(type, resource, params);

    case RESOURCES.TOOLS:
      return ToolsDataProvider(type, resource, params);

    case RESOURCES.TAXI:
      return TaxiDataProvider(type, resource, params);

    case RESOURCES.TRUCK:
      return TruckDataProvider(type, resource, params);

    case RESOURCES.COURSES:
      return CourseDataProvider(type, resource, params);

    case RESOURCES.DASHBOARD:
      return DashboardDataProvider(type, resource, params);

    case RESOURCES.SERVICEREQUESTS:
      return ServiceRequestsDataProvider(type, resource, params);

    default:
      return Promise.reject('Unknown Resource');
  }
};

export default DataProvider;
