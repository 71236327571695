import AssistantIcon from '@material-ui/icons/Assistant';
import NotificationCreate from './NotificationCreate';
import NotificationEdit from './NotificationEdit';
import NotificationList from './notification';

const Notification = {
  create: NotificationCreate,
  list: NotificationList,
  edit: NotificationEdit,
  icon: AssistantIcon
};
export default Notification;
