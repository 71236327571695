import CourseIcon from '@material-ui/icons/LibraryBooks';
//import CourseIcon from '@mui/icons-material/LibraryBooks';

import CourseList from './CourseList';
import CourseShow from './CourseShow';
import CourseEdit from './CourseEdit';

const Tools = {
  edit: CourseEdit,
  list: CourseList,
  show: CourseShow,
  icon: CourseIcon
};
export default Tools;
