import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountList from './Accounts';
// import AccountCreate from './AccountCreate';
// import AccountEdit from './AccountEdit';
import AccountShow from './AccountShow';

const Users = {
  // create: AccountCreate,
  //edit: AccountEdit,
  list: AccountList,
  show: AccountShow,
  icon: AccountBalanceIcon
};
export default Users;
