/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  SelectInput
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import {styles} from './AdminCreate';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
const currentStatusList = [
  {id: 'admin', name: 'Admin'},
  {id: 'user', name: 'User'}
];
const AdminEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

const AdminEdit = ({classes, ...props}) => (
  <Edit title="Edit  Admin" {...props}>
    <TabbedForm toolbar={<AdminEditToolbar />}>
      <FormTab label="admin.tabs.edit">
        <TextInput disabled source="id" />
        <TextInput autoFocus source="name" label="Name" validate={required()} formClassName={classes.first_name} />
        <TextInput disabled source="email" />
        <SelectInput source="role" choices={currentStatusList} />
        <TextInput type="phone" source="phone" fullWidth={true} formClassName={classes.email} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

AdminEdit.propTypes = {};

export default withStyles(styles)(AdminEdit);
