/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  SelectInput,
  //required,
  DeleteWithConfirmButton,
  Toolbar,
  //email,
  SaveButton,
  //Tab,
  ImageField
  //FileField,
  //TabbedShowLayout
} from 'react-admin';
import {withStyles} from '@material-ui/core';
export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
const role = localStorage.getItem('role');
const CourseEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton disabled={role != 'admin'} />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));
const currentStatusList = [
  {id: 'false', name: 'Pending'},
  {id: 'true', name: 'Approved'}
];
const CourseEdit = ({...props}) => (
  <Edit title="Edit Course Request" {...props}>
    <TabbedForm toolbar={<CourseEditToolbar />}>
      <FormTab label="Details">
        <TextInput disabled source="id" />
        <TextInput disabled source="vendorId" label="Vendor Id" />
        <TextInput source="vendorDetails[0].vendorName" label="Vendor Name" />
        <TextInput disabled source="courseName" label="Course Name" />
        <TextInput disabled source="rating" label="Rating" />
        <TextInput disabled source="vendorId" label="Approved Id" />
        <TextInput disabled source="description" />
        <SelectInput source="isAdminApproved" choices={currentStatusList} />
      </FormTab>
      <FormTab label="Images">
        <ImageField source="courseImagePath" label="" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

CourseEdit.propTypes = {};

export default withStyles(styles)(CourseEdit);
