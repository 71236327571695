import {apiUrl} from '../common/Globals';
import {HTTP_GET} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/dashboard';

const DashboardDataProvider = async (type /*, resource, params */) => {
  console.log('Query Type ', type);
  let res = await HTTP_GET(resourceBaseUlr);
  return res;
};
export default DashboardDataProvider;
