import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Requests from '../components/trainingrequests';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';
import {translate} from 'react-admin';
import {RESOURCES} from '../common/Resources';

import CardIcon from './CardIcon';

//import StarRatingField from '../reviews/StarRatingField';

const styles = (theme) => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20
  },
  titleLink: {
    fontWeight: 'bold',
    color: 'inherit'
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right'
  },
  title: {
    padding: '0 16px'
  },
  bold: {
    fontWeight: 'bold'
  },
  value: {
    padding: '0 16px',
    minHeight: 15
  },
  avatar: {
    background: theme.palette.background.avatar
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }
});

const Training = ({requests = [] /*, customers = {} */, nb, /* translate */ classes}) => (
  <div className={classes.main}>
    <CardIcon Icon={Requests.icon} bgColor="#f44336" actionPath={RESOURCES.TRAININGREQUESTS} />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {'Training'}
      </Typography>
      <Typography variant="headline" component="h2" className={classes.value}>
        <Link to={RESOURCES.TRAININGREQUESTS} className={classes.titleLink}>
          {nb}
        </Link>
      </Typography>
      <Divider />
      <List>
        {requests.map((record) => (
          <ListItem key={record.id} button component={Link} to={`/${RESOURCES.TRAININGREQUESTS}/${record.id}/show`}>
            <ListItemText primary={`${record.customerName}`} className={classes.titleLink} />
            <Typography className={classes.bold}>{`${record.courseName}`}</Typography>
          </ListItem>
        ))}
      </List>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Training);
