import {ShowController} from 'ra-core';
import React from 'react';
import {EmailField, ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';
const AdminShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="admin.tabs.show">
            <TextField source="id" />
            <TextField source="name" label="Name" />
            <EmailField source="email" label="Email" />
            <TextField source="phone" label="Phone" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default AdminShow;
