import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Requests from '../components/projectrequests';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';
import {translate} from 'react-admin';
import {RESOURCES} from '../common/Resources';

import CardIcon from './CardIcon';

const styles = (theme) => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20
  },
  titleLink: {
    fontWeight: 'bold',
    color: 'inherit'
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right'
  },
  title: {
    fontWeight: 'bold',
    padding: '0 16px'
  },
  value: {
    padding: '0 16px',
    minHeight: 15
  },
  avatar: {
    background: theme.palette.background.avatar
  },
  listItemText: {
    overflowY: 'hidden',
    fontStyle: 'bold',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  card1: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3
  },
  icon1: {
    float: 'right',
    width: 10,
    height: 10,
    padding: 14,
    color: '#fff'
  }
});

const Project = ({requests = [], nb, classes}) => {
  requests.map((record) => {
    console.log('record._id', record._id, ' record.customerName: ', record.customerName);
  });
  return (
    <div className={classes.main}>
      <CardIcon Icon={Requests.icon} bgColor="orange" actionPath={RESOURCES.PROJECTREQUESTS} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {'Project'}
        </Typography>
        <Typography variant="headline" component="h2" className={classes.value}>
          <Link to={RESOURCES.PROJECTREQUESTS} className={classes.titleLink}>
            {nb}
          </Link>
        </Typography>
        <Divider />
        <List>
          {requests.map((record) => (
            <ListItem key={record.id} button component={Link} to={`/${RESOURCES.PROJECTREQUESTS}/${record.id}/show`}>
              {/*<Avatar src={`${record.images[0]}`} className={classes.avatar} />*/}
              <ListItemText primary={`${record.customerName}`} className={classes.titleLink} />
              {/*<span> &#x20b9; {record.rate}</span>*/}
            </ListItem>
          ))}
        </List>
      </Card>
    </div>
  );
};

const enhance = compose(withStyles(styles), translate);

export default enhance(Project);
