import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE, CREATE, UPDATE} from 'react-admin';
import {fileUploadBase64Payload} from '../common/Utils';
import {HTTP_GET, HTTP_POST} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/truck';

const getTruck = async (params) => {
  console.log('GET_ONE_RentalREQUEST', params);
  const {id} = params;
  const url = `${resourceBaseUlr}/id/${id}`;
  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getTrucks = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const TruckCreate = async (params) => {
  const url = `${resourceBaseUlr}`;
  let ImgUrl = '';
  console.log('dddddddddd', params);
  if (params.data.images && params.data.images.title) {
    const imageUploadParams = {};
    const imagesss = await fileUploadBase64Payload([params.data.images], 'image');
    console.log('fileUploadBase64Payload imagesss ', imagesss);
    imageUploadParams.data = {};
    imageUploadParams.data.payloads = imagesss;
    imageUploadParams.data.resource = 'Truck';
    imageUploadParams.data.resourceName = params.data.equipmentType ? params.data.equipmentType : '';
    imageUploadParams.data.id = 1;
    const url = apiUrl + '/localupload';
    const body = imageUploadParams.data;
    let res = await HTTP_POST(url, body);
    if (res.success && res.data) {
      console.log('image upload response', res.data);
      ImgUrl = res.data;
    }
  }
  params.data.toolImagePath = ImgUrl;
  params.data.vendorId = 'ven6003';
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const TruckUpdate = async (params) => {
  const url = `${resourceBaseUlr}/update/${params['id']}`;
  console.log('dddddddddd-----dddtruck', params);
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const TrucksDataProvider = (type, resource, params) => {
  console.log('Query Type--truck----> ', type);
  switch (type) {
    case GET_LIST:
      return getTrucks(params);

    case GET_ONE:
      return getTruck(params);
    case CREATE:
      return TruckCreate(params);
    case UPDATE:
      return TruckUpdate(params);

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default TrucksDataProvider;
