import React from 'react';
import Card from '@material-ui/core/Card';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

const styles = {
  card: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3
  },
  icon: {
    float: 'right',
    width: 54,
    height: 54,
    padding: 14,
    color: '#fff'
  }
};

const CardIcon = ({Icon, classes, bgColor, actionPath}) => (
  <Link to={actionPath ? actionPath : ''}>
    <Card raised className={classes.card} style={{backgroundColor: bgColor}}>
      <Icon className={classes.icon} />
    </Card>
  </Link>
);

export default withStyles(styles)(CardIcon);
