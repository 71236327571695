import React from 'react';
import {List, Datagrid, TextField} from 'react-admin';

export const UnitList = (props) => (
  <List title=" Unit" {...props}>
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="unit" label="Unit" />
      <TextField source="geometry" label="Geometry" />
    </Datagrid>
  </List>
);
export default UnitList;
