import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {Card, CardActions} from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import {cyan500, pinkA200} from 'material-ui/styles/colors';
import authProvider from './../dataproviders/AuthProvider';
import {Notification, showNotification, translate, userLogin as userLoginAction} from 'react-admin';
import AppBar from 'material-ui/AppBar';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    minWidth: 300
  },
  avatar: {
    margin: '1em',
    textAlign: 'center '
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    display: 'flex'
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#ccc'
  }
};

function getColorsFromTheme(theme) {
  if (!theme) return {primary1Color: cyan500, accent1Color: pinkA200};
  const {
    palette: {primary1Color, accent1Color}
  } = theme;
  return {primary1Color, accent1Color};
}

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({meta: {touched, error} = {}, input: {...inputProps}, ...props}) => (
  <TextField errorText={touched && error} {...inputProps} {...props} fullWidth />
);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    console.log('ResetPassword constructor token', this.props.match.params.resetToken);
    let message = 'Verifying  Password reset link';
    this.state = {
      tokenValid: false,
      submitting: false,
      errorMessage: message,
      mailId: '',
      autoHideDuration: 2000
    };
    this.props.showNotification(message);
    setTimeout(this.checkTokenValidity, 1000);
  }

  checkTokenValidity = () => {
    console.log('ResetPassword checkTokenValidity', this.props.match.params.resetToken);
    let self = this;
    authProvider('RESET_PASSWORD', {resetToken: this.props.match.params.resetToken})
      .then((email) => {
        console.log('RESET_PASSWORD reset link ok for', email);
        self.setState({tokenValid: true, mailId: email, errorMessage: 'Verified  Password reset link'});
        //self.props.showNotification('Verified  Password reset link' );
      })
      .catch((error) => {
        console.log('RESET_PASSWORD error: ', JSON.stringify(error.message));
        let message = error.message;
        self.setState({errorMessage: message});
        self.props.showNotification(message);
      });
  };

  reset = ({resetToken, password}) => {
    let self = this;
    this.setState({submitting: true});
    resetToken = this.props.match.params.resetToken;
    console.log('New Password update ', resetToken);
    authProvider('UPDATE_PASSWORD', {resetToken, password})
      .then(() => {
        let message = 'New Password successfully updated';
        self.setState({errorMessage: message});
        self.props.showNotification(message);
      })
      .catch((error) => {
        console.log('error: ', JSON.stringify(error));
        self.setState({submitting: true});
        self.props.showNotification();
      });
  };

  onExitedFunction = () => {
    const {submitting, errorMessage} = this.state;
    if (submitting) {
      this.props.history.replace('../../');
    }
    this.setState({errorMessage: ''});
    console.log('onExitedFunction errorMessage: ', errorMessage, ' submitting: ', submitting);
  };

  render() {
    const {handleSubmit, theme, translate} = this.props;
    const {submitting, tokenValid, errorMessage, mailId, autoHideDuration} = this.state;
    const muiTheme = getMuiTheme(theme);
    const {primary1Color, accent1Color} = getColorsFromTheme(muiTheme);
    console.log('tokenValid:', tokenValid, ' email:', mailId, ' errorMessage: ', errorMessage);
    //const onExitedFunction = this.onExitedFunction;
    if (tokenValid) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <AppBar title="Update Password" />
            <div style={{...styles.main, backgroundColor: primary1Color}}>
              <Card style={styles.card}>
                <div style={styles.avatar}>
                  <Avatar backgroundColor={accent1Color} icon={<LockIcon />} size={60} />
                </div>
                <form onSubmit={handleSubmit(this.reset)}>
                  <div style={styles.form}>
                    <div style={styles.input}>
                      <TextField disabled name="email" floatingLabelText={translate('email')} defaultValue={mailId} />
                    </div>
                    <div style={styles.input}>
                      <Field
                        name="password"
                        component={renderInput}
                        floatingLabelText={translate('ra.auth.password')}
                        type="password"
                        hintText="Enter your password"
                      />
                    </div>
                    <div style={styles.input}>
                      <Field
                        name="password2"
                        component={renderInput}
                        floatingLabelText={translate('confirm password')}
                        type="password"
                        hintText="Confirm your password"
                      />
                    </div>
                  </div>
                  <CardActions>
                    <RaisedButton
                      type="submit"
                      primary
                      disabled={submitting}
                      label={translate('PASSWORD UPDATE')}
                      fullWidth
                    />
                  </CardActions>
                </form>
              </Card>
              <Notification message={errorMessage} autoHideDuration={autoHideDuration} />
            </div>
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <AppBar title="Reset Password" />
            <div style={{...styles.main, backgroundColor: primary1Color}}>
              <Card style={styles.card}>
                <div style={styles.avatar}>
                  <Avatar backgroundColor={accent1Color} icon={<LockIcon />} size={60} />
                </div>
              </Card>
              <Notification message={errorMessage} autoHideDuration={autoHideDuration} />
            </div>
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

ResetPassword.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  showNotification: PropTypes.func
};

ResetPassword.defaultProps = {
  theme: {}
};

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const {translate} = props;
      //if (!values.email) errors.email = translate('ra.validation.required');
      if (!values.password) errors.password = translate('ra.validation.required');
      if (!values.password2) errors.password2 = translate('ra.validation.required');
      if (values.password !== values.password2) errors.password2 = translate('ra.validation.password.not.matched');
      return errors;
    }
  }),
  connect(null, {userLogin: userLoginAction, showNotification})
);

export default enhance(ResetPassword);
