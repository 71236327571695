import React from 'react';
import {List, BulkDeleteWithConfirmButton, Datagrid, TextField, EditButton, Filter, TextInput} from 'react-admin';

const ServiceFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Service Code" source="serviceCode" alwaysOn />
    <TextInput label="Service Category Name" source="serviceCategoryName" alwaysOn />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};

const ServiceBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const ServiceList = (props) => (
  <List title=" Service" {...props} filters={<ServiceFilter />} bulkActionButtons={<ServiceBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="serviceName" label="Service Name" />
      <TextField source="serviceCode" />
      <TextField source="serviceCategoryName" label="Service Category Name" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default ServiceList;
