/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  //required,
  //NumberInput,
  Toolbar,
  SaveButton,
  CardActions,
  SelectInput
  //TextField,
  //Datagrid
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const currentStatusList = [
  {id: 'active', name: 'Active'},
  {id: 'deactive', name: 'Deactive'}
];
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const JobRequestRadiusEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));
const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);

const ProjectSpecificationsEdit = ({...props}) => (
  <Edit title="Edit Project Specifications" {...props} actions={<ListActions />}>
    <TabbedForm toolbar={<JobRequestRadiusEditToolbar />}>
      <FormTab label="">
        <TextInput source="specificationName" />
        <TextInput disabled source="serviceName" />
        <TextInput disabled source="geometry" />
        <TextInput disabled source="unit" />
        <SelectInput label="Status" source="currentStatus" choices={currentStatusList} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

ProjectSpecificationsEdit.propTypes = {};

export default withStyles(styles)(ProjectSpecificationsEdit);
