import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ProjectSpecificationsList from './ProjectSpecifications';
import ProjectSpecificationsShow from './ProjectSpecificationsShow';
import ProjectSpecificationsCreate from './ProjectSpecificationsCreate';
import ProjectSpecificationsEdit from './ProjectSpecificationsEdit';

const ProjectSpecifications = {
  create: ProjectSpecificationsCreate,
  list: ProjectSpecificationsList,
  show: ProjectSpecificationsShow,
  edit: ProjectSpecificationsEdit,
  icon: LinearScaleIcon
};
export default ProjectSpecifications;
