import {ShowController} from 'ra-core';
import React from 'react';
import {ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';
// const currentStatusList = [
//   {id: 'true', name: 'qualified'},
//   {id: 'false', name: 'disqualified'}
// ];
const RentalRequestsDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="vendorId" label="Vendor Id" />
            <TextField source="courseName" label="Course Name" />
            <TextField source="serviceName" label="ServiceName Name" />
            <TextField source="duration" label="Duration" />
            <TextField source="durationPeriod" label="Duration Period" />
            <TextField source="fees" label="fees" />
            <TextField source="description" label="description" />
            {/* <SelectInput source="isAdminApproved" choices={currentStatusList} /> */}
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default RentalRequestsDetailsShow;
