import {ShowController} from 'ra-core';
import React from 'react';
import {FileField, ImageField, ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const ManpowerRequestsDetailsShoww = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="customerName" />
            <TextField source="mobileNumber" />
            <TextField source="emailId" />
            <TextField source="serviceCode" />
            <TextField source="serviceId" />
            <TextField source="serviceName" />
            <TextField source="description" />
            <TextField source="noOfHours" />
            <TextField source="address" />
            <TextField source="localBody" />
            <TextField source="district" />
            <TextField source="state" />
            <TextField source="status" />
            <TextField source="approvedVendorId" />
            <TextField source="amountToBePaid" />
            <TextField source="amountPaid" />
            <TextField source="balanceAmount" />
          </Tab>
          <Tab label="Images">
            <ImageField source="imagePath1" label="" />
            <ImageField source="imagePath2" label="" />
            <ImageField source="imagePath3" label="" />
            <ImageField source="imagePath4" label="" />
          </Tab>
          <Tab label="Documents">
            <FileField source="documentPath1" title="document 1" label="" />
            <FileField source="documentPath2" title="document 2" label="" />
            <FileField source="documentPath3" title="document 3" label="" />
            <FileField source="documentPath4" title="document 4" label="" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default ManpowerRequestsDetailsShoww;
