import RentalIcon from '@material-ui/icons/Build';
import ToolsList from './ToolsList';
import ToolsShow from './ToolShow';
import ToolCreate from './ToolCreate';
import ToolEdit from './ToolEdit';

const Tools = {
  create: ToolCreate,
  list: ToolsList,
  show: ToolsShow,
  edit: ToolEdit,
  icon: RentalIcon
};
export default Tools;
