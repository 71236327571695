import React from 'react';
import 'react-phone-number-input/style.css';
import {getService} from '../../dataproviders/Service';
import {getUnit} from '../../dataproviders/Units';
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  CardActions,
  ArrayInput,
  //NumberInput,
  SimpleFormIterator
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};
const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const currentStatusList = [
  {id: 'active', name: 'Active'},
  {id: 'deactive', name: 'Deactive'}
];
const ProjectSpecificationsCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Project Specification" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Create Specification" path="">
        <ArrayInput source="items">
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              label="Service Name"
              reference="service"
              filter={{serviceCategoryName: 'Project'}}
              // validate={[required()]}
            >
              <SelectInput optionText="serviceName" />
            </ReferenceInput>
            <TextInput
              autoFocus
              source="specificationName"
              label="Specification Name"
              validate={required()}
              formClassName={classes.specification_name}
            />
            <ReferenceInput source="unitId" label="Default Unit" reference="units" validate={[required()]}>
              <SelectInput optionText="unit" />
            </ReferenceInput>
            <SelectInput label="Status" source="currentStatus" choices={currentStatusList} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
export default withStyles(styles)(ProjectSpecificationsCreate);
