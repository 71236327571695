import {ShowController} from 'ra-core';
import React from 'react';
import {ImageField, SelectInput, FormTab, ShowView, TabbedForm, TextField} from 'react-admin';
const currentStatusList = [
  {id: 'request_pending', name: 'request_pending'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'qualified', name: 'qualified'}
];
const TruckDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedForm>
          <FormTab label="Truck Details" path="">
            <TextField source="vendorId" label="Vendor Id" />
            <TextField source="Type" label="Truck Type" />
            <TextField source="rcownername" label="RC Owner Name" />
            <TextField source="platenumber" label="Register Number" />
            <TextField source="badge" label="Badge Number" />
            <TextField source="capacity" label="Passenger Capacity" />
            <TextField source="servicecharge" label="Service Charge (per Kilometer)" />
            <TextField source="description" label="Description" />
            <SelectInput source="currentStatus" choices={currentStatusList} />
            <TextField
              source="disqualifiedReason"
              multiline={true}
              numberOfLines={10}
              label="Reason for truck vendor disqualification"
            />
          </FormTab>
          <FormTab label="Images">
            <ImageField source="toolImagePath" label="" />
          </FormTab>
        </TabbedForm>
      </ShowView>
    )}
  </ShowController>
);

export default TruckDetailsShow;
