import React from 'react';
import 'react-phone-number-input/style.css';

import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  email,
  required,
  CardActions,
  PasswordInput,
  SelectInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const currentStatusList = [
  {id: 'admin', name: 'Admin'},
  {id: 'user', name: 'User'}
];
const AdminCreate = ({classes, ...props}) => (
  <Create {...props} title="Create  Admins" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="" path="">
        <TextInput autoFocus source="name" label="Name" validate={required()} formClassName={classes.first_name} />
        <TextInput type="email" source="email" validate={[required(), email()]} formClassName={classes.email} />
        <SelectInput source="role" choices={currentStatusList} />
        <TextInput type="tel" source="phone" formClassName={classes.phone} />
        <PasswordInput source="password" formClassName={classes.email} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(AdminCreate);
