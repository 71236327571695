import RentalIcon from '@material-ui/icons/CardTravel';
import TruckList from './TruckList';
import TruckShow from './TruckShow';
import TruckCreate from './TruckCreate';
import TruckEdit from './TruckEdit';

const Trucks = {
  //create: TruckCreate,
  list: TruckList,
  show: TruckShow,
  edit: TruckEdit,
  icon: RentalIcon
};
export default Trucks;
