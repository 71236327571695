import RentalIcon from '@material-ui/icons/Build';
import RentalRequestsList from './RentalRequests';
import RentalRequestsShow from './RentalRequestsShow';
import RentalRequestsEdit from './RentalRequestsEdit';

const RentalRequests = {
  list: RentalRequestsList,
  show: RentalRequestsShow,
  edit: RentalRequestsEdit,
  icon: RentalIcon
};
export default RentalRequests;
