import {ShowController} from 'ra-core';
import React from 'react';
import {
  ArrayField,
  ChipField,
  //Datagrid,
  EmailField,
  FileField,
  //FormTab,
  ImageField,
  ShowView,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  RichTextField
  //TextInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = {
  image: {
    maxHeight: '200px',
    maxWidth: '200px',
    borderRadius: '30%'
  }
};
const Title = ({record}) => {
  return <span>Vendor: {record ? `${record.vendorName}` : ''} </span>;
};
const VendorShow = ({...props}) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps} title={<Title />}>
        <TabbedShowLayout>
          <Tab label="Vendor Details">
            <TextField source="id" />
            <TextField source="vendorName" />
            <TextField source="phone" />
            <EmailField source="email" />
            <TextField source="serviceName" />
            <ArrayField source="profession">
              <SingleFieldList linkType={false}>
                <ChipField source="key" />
              </SingleFieldList>
            </ArrayField>
            <TextField source="qualification" />
            <TextField source="yearOfExperience" />
            <TextField source="type" />
            <TextField source="gender" />
          </Tab>

          <Tab label="Status">
            <TextField source="currentStatus" />
            <TextField source="workingStatus" />
            <TextField source="currentUserRole" />
            <TextField source="qualifiedDate" />
            <TextField source="manpowerStatus" />
            <TextField source="rentalStatus" />
            <TextField source="projectStatus" />
            <TextField source="trainerStatus" />
            <TextField source="profitPercentage" />
            <RichTextField source="disqualifiedReason" label="Reason for vendor disqualification" />
          </Tab>
          <Tab label="Address">
            <TextField source="address" />
            <TextField source="localbody" />
            <TextField source="district" />
            <TextField source="state" />
            <TextField source="pincode" />
            <TextField source="phone" />
            <EmailField source="email" />
          </Tab>
          <Tab label="Images">
            <ImageField source="passportImagePath" src="passportImagePath" label="passport image" />
            <ImageField source="liveImagePath" src="liveImagePath" label="live image" />
          </Tab>
          <Tab label="Documents">
            <FileField source="cvUploadPath" title="CV" label="" />
            <FileField source="certificateUploadPath" title="certificate" label="" />
          </Tab>
          {/* <Tab label="Manpower request">
            <TextField />
          </Tab> */}
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default withStyles(styles)(VendorShow);
