import {ShowController} from 'ra-core';
import React from 'react';
import {ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const ProjectSpecificationsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="">
            <TextField source="specificationName" />
            <TextField source="serviceName" />
            <TextField source="geometry" />
            <TextField source="unit" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default ProjectSpecificationsShow;
