import React from 'react';
import {stringify} from 'query-string';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';

const stopPropagation = (e) => e.stopPropagation();
const VendorTransactions = ({record = {}, icon = <ListIcon />, fieldName, ...rest}) => {
  const querry = {};
  querry[`${fieldName}`] = record.id;
  const search = stringify({
    page: 1,
    perPage: 25,
    sort: 'id',
    order: 'DESC',
    filter: JSON.stringify(querry)
  });
  console.log('Husain search: ', search);
  return (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{pathname: '/accounts', search: search}}
      label={'Transaction'}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
};

export default VendorTransactions;
