import CategoryIcon from '@material-ui/icons/Category';
import ServiceEdit from './ServiceEdit';
import ServiceList from './Service';
import ServiceShow from './ServiceShow';
import ServiceCreate from './ServiceCreate';

const Services = {
  create: ServiceCreate,
  edit: ServiceEdit,
  list: ServiceList,
  show: ServiceShow,
  icon: CategoryIcon
};
export default Services;
