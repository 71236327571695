import {ShowController} from 'ra-core';
import React from 'react';
import {ImageField, ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const RentalRequestsDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="customerName" />
            <TextField source="equipmentType" />
            <TextField source="equipmentSpecification" label="Equipment Spec" />
            <TextField source="brand" />
            <TextField source="mobileNumber" />
            <TextField source="emailId" />
            <TextField source="description" />
            <TextField source="address" />
            <TextField source="localBody" />
            <TextField source="district" />
            <TextField source="state" />
            <TextField source="approvedVendorId" />
            <TextField source="amountToBePaid" />
            <TextField source="amountPaid" />
            <TextField source="balanceAmount" />
            <TextField source="status" />
          </Tab>
          <Tab label="Images">
            <ImageField source="toolImagePath" label="" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default RentalRequestsDetailsShow;
