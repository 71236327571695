import {ShowController} from 'ra-core';
import React from 'react';
import {ImageField, SelectInput, FormTab, ShowView, TabbedForm, TextField} from 'react-admin';
const currentStatusList = [
  {id: 'pending approval', name: 'pending approval'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'qualified', name: 'qualified'}
];
const TaxiDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedForm>
          <FormTab label="Taxi Details" path="">
            <TextField source="vendorId" label="Vendor Id" />
            <TextField source="Type" label="Taxi Type" />
            <TextField source="rcownername" label="RC Owner Name" />
            <TextField source="platenumber" label="Register Number" />
            <TextField source="badge" label="Badge Number" />
            <TextField source="badgearea" label="Badge Area" />
            <TextField source="insurancenumber" label="Insurance Number" />
            <TextField source="insurancedate" label="Insurance Expiry Date" />
            <TextField source="taxvalidity" label="Tax Validity" />
            <TextField source="capacity" label="Passenger Capacity" />
            <TextField source="servicecharge" label="Service Charge (per Kilometer)" />
            <TextField source="description" label="Description" />
            <SelectInput source="currentStatus" choices={currentStatusList} />
          </FormTab>
          <FormTab label="Images">
            <ImageField source="toolImagePath" label="" />
          </FormTab>
        </TabbedForm>
      </ShowView>
    )}
  </ShowController>
);

export default TaxiDetailsShow;
