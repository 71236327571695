import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //EmailField,
  //EditButton,
  Filter,
  TextInput,
  ImageField
  //DateInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
// import {UserList} from '../users/Users';

const ToolsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput source="vendorId" label="Vendor Id" alwaysOn />
    <TextInput source="equipmentType" label="Equipment Type" alwaysOn />
    <TextInput source="brand" label="Brand" alwaysOn />
    <TextInput source="rent" label="Rental Charge" alwaysOn />
    <TextInput source="description" label="Description" />
  </Filter>
);

const rowClick = (/*id, basePath, record */) => {
  // if (record.id === 0) {
  //   return 'show';
  // }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const ToolsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

const styles = {
  image: {
    maxHeight: '50px',
    maxWidth: '50px',
    borderRadius: '30%'
  }
};
export const ToolsList = ({classes, ...props}) => (
  <List title=" Tools" {...props} filters={<ToolsFilter />} bulkActionButtons={<ToolsBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="equipmentType" label="Equipment Type" />
      <TextField source="equipmentSpecification" label="Equipment Spec" />
      <TextField source="brand" label="Brand" />
      <TextField source="rent" label="Rental Charge" />
      <TextField source="estimationType" label="Estimation Type" />
      <TextField source="description" label="Description" />
      <ImageField source="toolImagePath" label="photo" classes={classes} />
    </Datagrid>
  </List>
);

export default withStyles(styles)(ToolsList);
