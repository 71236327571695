import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, DELETE_MANY} from 'react-admin';
import {HTTP_GET, HTTP_POST, HTTP_DELETE} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/project/specification';

const getProjectSpecification = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/id/${id}`;
  console.log('getProjectSpecification url ', url);

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getProjectSpecifications = async (params) => {
  console.log('parameter', params);
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  console.log('query', query);
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const createProjectSpecification = async (params) => {
  console.log('parameter-----------786->>>', params);
  const url = `${resourceBaseUlr}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const updateProjectSpecification = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/id/${id}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteProjectSpecification = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/id/${id}`;
  let res = await HTTP_DELETE(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteMany = async (params) => {
  const url = `${resourceBaseUlr}`;
  const body = params;
  let res = await HTTP_DELETE(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const getList = (resource, params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([page * perPage, (page + 1) * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${apiUrl}/${resource}?${stringify(query)}`;

  return httpClient(url).then(({headers, json}) => ({
    data: json,
    total: parseInt(headers.get('content-range').split('/').pop(), 10)
  }));
};
const ProjectSpecificationDataProvider = (type, resource, params) => {
  console.log('Query Type ', type);
  switch (type) {
    case GET_LIST:
      return getProjectSpecifications(params);

    case GET_ONE:
      return getProjectSpecification(params);

    case CREATE:
      return createProjectSpecification(params);

    case UPDATE:
      return updateProjectSpecification(params);

    case DELETE:
      return deleteProjectSpecification(params);

    case DELETE_MANY:
      return deleteMany(params);

    case getList:
      return getList();

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default ProjectSpecificationDataProvider;
