import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import {AdminList} from './Admins';
import AdminCreate from './AdminCreate';
import AdminEdit from './AdminEdit';
import AdminShow from './AdminShow';

const Users = {
  create: AdminCreate,
  edit: AdminEdit,
  list: AdminList,
  show: AdminShow,
  icon: SupervisedUserCircleIcon
};
export default Users;
