import AssistantIcon from '@material-ui/icons/Assistant';
import ServiceCategoryList from './ServiceCategory';
import ServiceCategoryEdit from './ServiceCategoryEdit';

const ServiceCategies = {
  list: ServiceCategoryList,
  icon: AssistantIcon,
  edit: ServiceCategoryEdit
};
export default ServiceCategies;
