import {ShowController} from 'ra-core';
import React from 'react';
import {EmailField, ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const CompanyDetailsShoww = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="">
            <TextField source="id" />
            <TextField source="companyName" label="Company Name" />
            <TextField source="localBody" label="Local Body" />
            <TextField source="district" label="District" />
            <TextField source="state" label="State" />
            <TextField source="pincode" label="Pincode" />
            <TextField source="phone" label="Phone" />
            <TextField source="whatsappNumber" label="Whatsapp Number" />
            <EmailField source="email" label="Email" />
            <TextField source="profileDescription" label="Profile Description" />
            <TextField source="aboutUs" label="About Us" />
            <TextField source="mission" label="Mission" />
            <TextField source="vision" label="Vision" />
            <TextField source="goal" label="Goal" />
            <TextField source="facebookLink" label="Facebook Link" />
            <TextField source="twitterLink" label="Twitter Link" />
            <TextField source="instagramLink" label="Instagram Link" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default CompanyDetailsShoww;
