import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  TextField,
  ImageField
  //   FileInput,
  //   ImageInput,
  //   FileField
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import {styles} from './UserCreate';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
const role = localStorage.getItem('role');
const UserEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton disabled={role != 'admin'} />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

const Title = ({record}) => {
  return <span>Edit: {record ? `${record.name}` : ''} </span>;
};
const UserEdit = ({classes, ...props}) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm toolbar={<UserEditToolbar />}>
      <FormTab label="User Details">
        <TextInput disabled source="id" />
        <TextInput autoFocus source="name" label="Name" validate={required()} formClassName={classes.first_name} />
        <TextInput disabled source="email" />
        <TextInput type="phone" source="phone" formClassName={classes.email} />
      </FormTab>
      <FormTab label="Address">
        <TextField source="address" />
        <TextField source="district" />
        <TextField source="state" />
        <TextField source="localBody" />
      </FormTab>
      <FormTab label="Geo Location">
        <TextField source="latitude" />
        <TextField source="longitude" />
      </FormTab>
      <FormTab label="Image" path="">
        <ImageField source="profileImagePath" label="photo" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

UserEdit.propTypes = {};

export default withStyles(styles)(UserEdit);
