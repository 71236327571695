import PanToolIcon from '@material-ui/icons/PanTool';
import ManpowerRequestsEdit from './ManpowerRequestsEdit';
import ManpowerRequestsList from './ManpowerRequests';
import ManpowerRequestsShow from './ManpowerRequestsShow';
//import ManpowerRequestsCreate from './ManpowerRequestsCreate';

const ManpowerRequests = {
  //create: ManpowerRequestsCreate,
  edit: ManpowerRequestsEdit,
  list: ManpowerRequestsList,
  show: ManpowerRequestsShow,
  icon: PanToolIcon
};
export default ManpowerRequests;
