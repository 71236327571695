import React, {Component} from 'react';
import {GET_LIST, Responsive, withDataProvider} from 'react-admin';
import compose from 'recompose/compose';
import {connect} from 'react-redux';

import Welcome from './Welcome';
import Manpower from './Manpower';
import Project from './Project';
import Rental from './Rental';
import Training from './Training';

import Users from '../components/users';
import Vendors from '../components/vendors';
import Tools from '../components/rentalrequests';
import Courses from '../components/trainingrequests';

import ResourceCountCard from './ResourceCountCard';

import {RESOURCES} from '../common/Resources';
const styles = {
  backgrougd: {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${'logo.jpeg'})`
  },
  flex: {display: 'flex'},
  flexColumn: {display: 'flex', flexDirection: 'column'},
  leftCol: {flex: 1, marginRight: '1em'},
  rightCol: {flex: 1, marginLeft: '1em'},
  singleCol: {marginBottom: '1px'}
};

const Spacer = () => <span style={{width: '1em'}} />;
class Dashboard extends Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  fetchData() {
    this.fetchDashboadData();
  }

  async fetchDashboadData() {
    const {dataProvider} = this.props;
    const resp = await dataProvider(GET_LIST, 'dashboard', {});
    console.log('resp: ', resp);

    const nbUsers = resp.data.nbUsers;
    const nbVendors = resp.data.nbVendors;
    const nbTools = resp.data.nbTools;
    const nbCourses = resp.data.nbCourses;

    const nbManpowerRequest = resp.data.nbManpowerRequest;
    const nbProjectRequest = resp.data.nbProjectRequest;
    const nbMRentalRequest = resp.data.nbMRentalRequest;
    const nbTrainingRequest = resp.data.nbTrainingRequest;

    const manpowerRequests = resp.data.manpowerRequests;
    const projectRequests = resp.data.projectRequests;
    const rentalRequests = resp.data.rentalRequests;
    const trainingRequests = resp.data.trainingRequests;

    this.setState({
      nbUsers,
      nbVendors,
      nbTools,
      nbCourses,

      nbManpowerRequest,
      nbProjectRequest,
      nbMRentalRequest,
      nbTrainingRequest,

      manpowerRequests,
      projectRequests,
      rentalRequests,
      trainingRequests
    });
  }

  render() {
    const {
      nbUsers,
      nbVendors,
      nbTools,
      nbCourses,

      nbManpowerRequest,
      nbProjectRequest,
      nbMRentalRequest,
      nbTrainingRequest,

      manpowerRequests,
      projectRequests,
      rentalRequests,
      trainingRequests
    } = this.state;

    return (
      <Responsive
        medium={
          <div>
            <div style={styles.singleCol}>
              <Welcome />
            </div>
            <div style={styles.flex}>
              <div style={styles.leftCol}>
                <div style={styles.flex}>
                  <ResourceCountCard
                    resource={RESOURCES.USERS}
                    count={nbUsers}
                    icon={Users.icon}
                    actionPath={RESOURCES.USERS}
                  />
                  <Spacer />
                  <ResourceCountCard resource={RESOURCES.VENDORS} count={nbVendors} icon={Vendors.icon} />
                  <Spacer />
                  <ResourceCountCard resource={RESOURCES.TOOLS} count={nbTools} icon={Tools.icon} />
                  <Spacer />
                  <ResourceCountCard resource={RESOURCES.COURSES} count={nbCourses} icon={Courses.icon} />
                </div>
              </div>
            </div>
            <div style={styles.flex}>
              <div style={styles.leftCol}>
                <div style={styles.flex}>
                  <Manpower nb={nbManpowerRequest} requests={manpowerRequests} />
                  <Project nb={nbProjectRequest} requests={projectRequests} />
                  <Rental nb={nbMRentalRequest} requests={rentalRequests} />
                  <Training nb={nbTrainingRequest} requests={trainingRequests} />
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  version: state.admin.ui.viewVersion
});

export default compose(connect(mapStateToProps), withDataProvider)(Dashboard);
