import React, {useState} from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  SelectInput,
  ImageField
} from 'react-admin';
import {withStyles} from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ToolEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

const currentStatusList = [
  {id: 'pending approval', name: 'pending approval'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'approved', name: 'approved'}
];
const estimationTypeList = [
  {id: 'hours', name: 'hours'},
  {id: 'days', name: 'days'},
  {id: 'months', name: 'months'},
  {id: 'years', name: 'years'}
];
const Title = ({record}) => {
  return <span>Edit: {record ? `${record.rcownername}` : ''} </span>;
};
const ToolEdit = ({...props}) => {
  const [currentStatus, setCurrentStatus] = useState('');

  const handleStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };

  return (
    <Edit title={<Title />} {...props}>
      <TabbedForm toolbar={<ToolEditToolbar />}>
        <FormTab label="Tool Details">
          <TextInput disabled source="id" />
          <TextInput source="vendorDetails[0].vendorName" label="Vendor Name" />
          <SelectInput source="estimationType" choices={estimationTypeList} />
          <TextInput source="rent" label="Equipment Rent" />
          <TextInput source="equipmentSpecification" label="Equipment Specification" />
          <TextInput source="brand" label="Equipment Brand" />
          <TextInput source="description" label="Description" />
          <SelectInput source="currentStatus" choices={currentStatusList} onChange={handleStatusChange} />
          {currentStatus === 'disqualified' && (
            <TextInput
              source="disqualifiedReason"
              multiline={true}
              numberOfLines={10}
              label="Reason for disqualification"
            />
          )}
        </FormTab>
        <FormTab label="Images">
          <ImageField source="toolImagePath" label="" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const styles = {}; // Define an empty styles object or your custom styles

export default withStyles(styles)(ToolEdit);
