/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  NumberInput,
  Toolbar,
  SaveButton,
  //   Datagrid,
  //   TextField,
  CardActions
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ServiceCategoryEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const ServiceCategoryEdit = ({classes, ...props}) => (
  <Edit title="Edit Job Request Radius" {...props} actions={<ListActions />}>
    <TabbedForm toolbar={<ServiceCategoryEditToolbar />}>
      <FormTab label="">
        <TextInput disabled source="serviceCategoryName" />
        <TextInput source="serviceCode" />
        <NumberInput
          autoFocus
          source="profitPercentage"
          label="Profit Percentage"
          validate={required()}
          formClassName={classes.first_name}
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);

ServiceCategoryEdit.propTypes = {};

export default withStyles(styles)(ServiceCategoryEdit);
