import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE} from 'react-admin';

import {HTTP_GET /*, HTTP_POST, HTTP_DELETE */} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/client/transaction/vendor/summary';

const getClientTransactionRequest = async (params) => {
  console.log('GET_ONE_ClientTransactionRequest params: ', params);
  const {id} = params;
  console.log('GET_ONE_ClientTransactionRequest id: ', id);
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getClientTransactionRequests = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  let {filter} = params;
  console.log('GET_ONE_ClientTransactionRequest filter: ', filter);
  let vendorTransactionUri = '';
  if (filter && filter.vendorTransaction) {
    delete filter.vendorTransaction;
    let vendorId = filter.vendorId;
    delete filter.vendorId;
    vendorTransactionUri = '/vendor/' + vendorId;
  }
  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(filter)
  };

  const url = `${resourceBaseUlr}${vendorTransactionUri}?${stringify(query)}`;
  console.log('Husain  GET_ONE_ClientTransactionRequest params.filter: ', params.filter);
  console.log('Husain GET_ONE_ClientTransactionRequest params.url: ', url);

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const ClientTransactionSummaryDataProvider = (type, resource, params) => {
  console.log('Husain Query Type ', type, 'resource ', resource, 'params:', params);
  switch (type) {
    case GET_LIST:
      return getClientTransactionRequests(params);

    case GET_ONE:
      return getClientTransactionRequest(params);

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default ClientTransactionSummaryDataProvider;
