import React from 'react';
import 'react-phone-number-input/style.css';
// import {apiUrl} from '../../common/Globals';
// import {HTTP_GET} from '../../common/HttpService';
import {getServiceCategory} from '../../dataproviders/ServiceCategory';
import {
  Create,
  FormTab,
  TabbedForm,
  //TextField,
  TextInput,
  FormDataConsumer,
  //AutocompleteInput,
  ReferenceInput,
  ImageField,
  ImageInput,
  SelectInput,
  required,
  CardActions
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';
export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const audienceList = [
  {id: 'customers', name: 'customers'},
  {id: 'vendors', name: 'vendors'}
];
const NotificationCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Notification" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Notification Details" path="">
        <SelectInput source="audience" choices={audienceList} />
        <ReferenceInput
          source="serviceCategory"
          label="Select Service Category"
          reference="serviceCategory"
          validate={[required()]}
        >
          <SelectInput optionText="serviceCategoryName" />
        </ReferenceInput>
        <TextInput
          autoFocus
          source="notName"
          label="Notification Name"
          validate={required()}
          formClassName={classes.notification_name}
        />
        <TextInput
          autoFocus
          multiline={true}
          numberOfLines={10}
          source="notContent"
          label="Notification Content"
          validate={required()}
          formClassName={classes.notification_content}
        />
        <FormDataConsumer>
          {({formData}) => {
            console.log(formData);
            if (formData.serviceCategoryId !== undefined) {
              var t = formData.serviceCategoryId;
              getServiceCategory({id: t}).then(function (response) {
                console.log('!!!!!');
                console.log(response.data.serviceCategoryName);
                formData.serviceCategoryName = response.data.serviceCategoryName;
              });
            }
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Images">
        <ImageField source="imagePath" title="imagePath" />
        {/* <ImageField source="imagePath" src="imagePath" label="Service image" /> */}
        <ImageInput
          source="images"
          label="images"
          accept="image/*"
          maxSize={5000000}
          placeholder={
            <p>
              Upload Image
              <span>*File size should not exceed 5MB</span>
            </p>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(NotificationCreate);
