import React from 'react';
import 'react-phone-number-input/style.css';
// import {apiUrl} from '../../common/Globals';
// import {HTTP_GET} from '../../common/HttpService';
import {getServiceCategory} from '../../dataproviders/ServiceCategory';

import {
  Create,
  FormTab,
  TabbedForm,
  //TextField,
  TextInput,
  FormDataConsumer,
  //AutocompleteInput,
  ReferenceInput,
  ImageField,
  ImageInput,
  SelectInput,
  required,
  CardActions
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);

const ServiceCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Service" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="" path="">
        <TextInput
          autoFocus
          source="serviceName"
          label="Service Name"
          validate={required()}
          formClassName={classes.service_name}
        />
        <TextInput
          source="serviceCode"
          label="Service Code"
          validate={required()}
          formClassName={classes.service_code}
        />
        <ReferenceInput
          source="serviceCategoryId"
          label="Service Category"
          reference="serviceCategory"
          validate={[required()]}
        >
          <SelectInput optionText="serviceCategoryName" />
        </ReferenceInput>
        <ImageInput
          multiple={false}
          source="images"
          accept="image/*"
          maxSize={1000000}
          placeholder={
            <p>
              Drop a picture to upload, or click to select it.
              <br /> <br />
              <span>Image size cannot be more than 1MB </span>
            </p>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({formData}) => {
            console.log('hereee-----|oo|--', formData.serviceCategoryId);
            console.log(formData);
            if (formData.serviceCategoryId !== undefined) {
              var t = formData.serviceCategoryId;
              getServiceCategory({id: t}).then(function (response) {
                console.log(response.data.serviceCategoryName);
                formData.serviceCategoryName = response.data.serviceCategoryName;
              });
            }
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(ServiceCreate);
