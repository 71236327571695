// import {apiUrl} from '../common/Globals';
// import {fileUploadBase64Payload} from '../common/Utils';

// import {stringify} from 'query-string';
// import {GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, DELETE_MANY} from 'react-admin';

// import {HTTP_GET, HTTP_POST, HTTP_DELETE} from '../common/HttpService';

// const resourceBaseUlr = apiUrl + '/users';

// const getUser = async (params) => {
//   console.log('getUser, params', params);
//   const {id} = params;
//   console.log('getUser, id', id);
//   const url = `${resourceBaseUlr}/${id}`;
//   let res = await HTTP_GET(url);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const getUsers = async (params) => {
//   const {page, perPage} = params.pagination;
//   const {field, order} = params.sort;

//   const query = {
//     sort: JSON.stringify([field, order]),
//     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
//     filter: JSON.stringify(params.filter)
//   };
//   const url = `${resourceBaseUlr}?${stringify(query)}`;

//   let res = await HTTP_GET(url);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const createUser = async (params) => {
//   const url = `${resourceBaseUlr}+'/register'`;
//   const body = params.data;
//   let res = await HTTP_POST(url, body);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const updateUser = async (params) => {
//   const {id} = params;
//   const url = `${resourceBaseUlr}/${id}`;
//   const body = params.data;
//   //console.log('updateUser ', body);
//   console.log('updateUser params.data.images', params.data.images);
//   //Image upload sample code image formated to base64
//   if (params.data.images && params.data.images.length) {
//     const imageUploadParams = {};
//     const imagesss = await fileUploadBase64Payload(params.data.images, 'image');
//     console.log('fileUploadBase64Payload imagesss ', imagesss);
//     imageUploadParams.data = {};
//     imageUploadParams.data.payloads = imagesss;
//     imageUploadParams.data.resource = 'user';
//     imageUploadParams.data.id = id;
//     const url = apiUrl + '/upload';
//     const body = imageUploadParams.data;
//     let res = await HTTP_POST(url, body);
//     console.log('image upload response');
//   }
//   if (params.data.documents && params.data.documents.length) {
//     const dccumentUploadParams = {};
//     const documents = await fileUploadBase64Payload(params.data.documents, 'document');
//     dccumentUploadParams.data = {};
//     dccumentUploadParams.data.payloads = documents;
//     dccumentUploadParams.data.resource = 'user';
//     dccumentUploadParams.data.id = id;
//     // const url = apiUrl + '/upload';
//     // const body = dccumentUploadParams.data;
//     console.log('document upload response');
//   }
//   let res = await HTTP_POST(url, body);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const deleteUser = async (params) => {
//   const {id} = params;
//   const url = `${resourceBaseUlr}/${id}`;
//   let res = await HTTP_DELETE(url);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const deleteMany = async (params) => {
//   const url = `${resourceBaseUlr}`;
//   const body = params;
//   let res = await HTTP_DELETE(url, body);
//   if (!res.success) {
//     return new Promise(function (resolve, reject) {
//       reject({message: res.data});
//     });
//   }
//   return res;
// };

// const UserDataProvider = (type, resource, params) => {
//   console.log('Query Type ', type);
//   switch (type) {
//     case GET_LIST:
//       return getUsers(params);

//     case GET_ONE:
//       return getUser(params);

//     case CREATE:
//       return createUser(params);

//     case UPDATE:
//       return updateUser(params);

//     case DELETE:
//       return deleteUser(params);

//     case DELETE_MANY:
//       return deleteMany(params);

//     default:
//       console.log('Unknown Type ', type);
//       return Promise.reject('Unknown Type');
//   }
// };

// export default UserDataProvider;
