import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  TextInput
} from 'react-admin';

const AdminFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Email" source="email" />
    <TextInput label="Name" source="name" />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};

const AdminBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const AdminList = (props) => (
  <List title=" Admins" {...props} filters={<AdminFilter />} bulkActionButtons={<AdminBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<AdminEdit/> }*/ {...props}>
      <TextField source="id" />
      <TextField source="name" label="Name" />
      <EmailField source="email" label="Email" />
      <TextField type="tel" source="phone" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default AdminList;
