import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //   EditButton,
  //   EmailField,
  Filter,
  TextInput,
  DateInput
} from 'react-admin';

const ManpowerRequestsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" />
    <DateInput source="endDate" />
    <TextInput label="Customer Name" source="customerName" alwaysOn />
    <TextInput label="Service Code" source="serviceCode" alwaysOn />
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Mobile" source="mobileNumber" alwaysOn />
    <TextInput label="Description" source="description" />
    <TextInput label="EstimationType" source="estimationType" />
    <TextInput label="Address" source="address" />
    <TextInput label="LocalBody" source="localBody" />
    <TextInput label="District" source="district" />
    <TextInput label="State" source="state" />
    <TextInput label="Pincode" source="pincode" />
    <TextInput label="AmountToBePaid" source="amountToBePaid" />
    <TextInput label="AmountPaid" source="amountPaid" />
    <TextInput label="BalanceAmount" source="balanceAmount" />
    <TextInput label="CreatedAt" source="createdAt" />
    <TextInput label="Approved Vendor" source="approvedVendorId" />
    <TextInput label="Status" source="status" alwaysOn />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const ManpowerRequestsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;
const role = localStorage.getItem('role');
export const ManpowerRequestsList = (props) => (
  <List
    title=" Manpower Requests"
    {...props}
    filters={<ManpowerRequestsFilter />}
    bulkActionButtons={<ManpowerRequestsBulkActionButtons disabled={role != 'admin'} />}
  >
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="customerName" />
      <TextField source="mobileNumber" label="Mobile" />
      <TextField source="serviceCode" />
      <TextField source="serviceName" />
      <TextField source="description" />
      <TextField source="noOfHours" label="Working Time" />
      <TextField source="estimationType" />
      <TextField source="address" />
      <TextField source="localBody" />
      <TextField source="district" />
      <TextField source="state" />
      <TextField source="pincode" />
      <TextField source="amountToBePaid" label="Total Amount" />
      <TextField source="amountPaid" />
      <TextField source="balanceAmount" />
      <TextField source="approvedVendorId" />
      <TextField source="status" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default ManpowerRequestsList;
