import {ShowController} from 'ra-core';
import React from 'react';
import {ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const ServiceShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="">
            <TextField source="serviceName" label="Service Name" />
            <TextField source="serviceCode" />
            <TextField source="serviceCategoryName" label="Service Category Name" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default ServiceShow;
