import UserIcon from '@material-ui/icons/PeopleOutline';
import UserEdit from './UserEdit';
import UserList from './Users';
import UserShow from './UserShow';
//import UserCreate from './UserCreate';

const Users = {
  //create: UserCreate,
  edit: UserEdit,
  list: UserList,
  show: UserShow,
  icon: UserIcon
};
export default Users;
