/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  SelectInput,
  //required,
  DeleteWithConfirmButton,
  Toolbar,
  //email,
  SaveButton,
  //Tab,
  ImageField,
  FileField
  //TabbedShowLayout
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import {styles} from './ManpowerRequestsCreate';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
const role = localStorage.getItem('role');
const ManpowerRequestsEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton disabled={role != 'admin'} />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));
const currentStatusList = [
  {id: 'false', name: 'Pending'},
  {id: 'true', name: 'Approved'}
];
const ManpowerRequestsEdit = ({...props}) => (
  <Edit title="Edit Manpower Requests" {...props}>
    <TabbedForm toolbar={<ManpowerRequestsEditToolbar />}>
      <FormTab label="Details">
        <TextInput disabled source="id" />
        <TextInput disabled source="customerId" />
        <TextInput disabled source="customerName" />
        <TextInput disabled source="mobileNumber" />
        <TextInput disabled source="emailId" />
        <TextInput disabled source="serviceCode" />
        <TextInput disabled source="serviceId" />
        <TextInput disabled source="serviceName" />
        <TextInput disabled source="description" />
        <TextInput disabled source="noOfHours" />
        <TextInput disabled source="address" />
        <TextInput disabled source="localBody" />
        <TextInput disabled source="district" />
        <TextInput disabled source="state" />
        <TextInput disabled source="status" />
        <TextInput disabled source="approvedVendorId" />
        <TextInput disabled source="amountToBePaid" />
        <TextInput disabled source="amountPaid" />
        <TextInput disabled source="balanceAmount" />
        <SelectInput source="isAdminApproved" choices={currentStatusList} />
      </FormTab>
      <FormTab label="Images">
        <ImageField source="imagePath1" label="" />
        <ImageField source="imagePath2" label="" />
        <ImageField source="imagePath3" label="" />
        <ImageField source="imagePath4" label="" />
      </FormTab>
      <FormTab label="Documents">
        <FileField source="documentPath1" title="document 1" label="" />
        <FileField source="documentPath2" title="document 2" label="" />
        <FileField source="documentPath3" title="document 3" label="" />
        <FileField source="documentPath4" title="document 4" label="" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

ManpowerRequestsEdit.propTypes = {};

export default withStyles(styles)(ManpowerRequestsEdit);
