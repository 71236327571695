import React from 'react';
import 'react-phone-number-input/style.css';

import {Create, FormTab, TabbedForm, TextInput, email, required, CardActions} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block', width: 10},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const UserCreate = ({classes, ...props}) => (
  <Create {...props} title="Create  Users" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="" path="">
        <TextInput autoFocus source="name" label="Name" validate={required()} formClassName={classes.first_name} />
        <TextInput
          type="email"
          source="email"
          validate={[required(), email()]}
          fullWidth={true}
          formClassName={classes.email}
        />
        <TextInput type="tel" source="phone" fullWidth={true} formClassName={classes.email} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(UserCreate);
