import {ShowController} from 'ra-core';
import React from 'react';
import {EmailField, ImageField, ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const Title = ({record}) => {
  return <span>Customer: {record ? `${record.name}` : ''} </span>;
};
const UserShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps} title={<Title />}>
        <TabbedShowLayout>
          <Tab label="User Details">
            <TextField source="id" />
            <TextField source="userId" />
            <TextField source="name" label="Name" />
            <TextField source="phone" label="Phone" />
            <EmailField source="email" label="Email" />
            <TextField source="userRole" />
          </Tab>
          <Tab label="Address">
            <TextField source="id" />
            <TextField source="address" />
            <TextField source="district" />
            <TextField source="state" />
            <TextField source="localBody" />
          </Tab>
          <Tab label="Geo Location">
            <TextField source="id" />
            <TextField source="latitude" />
            <TextField source="longitude" />
          </Tab>
          <Tab label="Image">
            <ImageField source="profileImagePath" label="photo" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default UserShow;
