import RentalIcon from '@material-ui/icons/Traffic';
import TaxiList from './TaxiList';
import TaxiShow from './TaxiShow';
import TaxiCreate from './TaxiCreate';
import TaxiEdit from './TaxiEdit';

const Tools = {
  //create: TaxiCreate,
  list: TaxiList,
  edit: TaxiEdit,
  show: TaxiShow,
  icon: RentalIcon
};
export default Tools;
