export const errorCodes = {
  UNDEFINED: 0,
  SERVER_OK: 200,
  SERVER_BAD_REQUEST: 400,
  SERVER_DATA_NOT_FOUND: 404,
  SERVER_AUTH_FAILED: 403,
  REQUEST_TIMED_OUT: 408,
  SERVER_INTERNAL_ERROR: 500,
  NETWORK_ERROR: 503
};

export const errorStrings = {
  UNDEFINED: 'Please try again later',
  SERVER_OK: 'Success',
  SERVER_BAD_REQUEST: 'Bad Request',
  SERVER_DATA_NOT_FOUND: 'Data Not Found',
  SERVER_AUTH_FAILED: 'Not Authorized',
  REQUEST_TIMED_OUT: 'Request Timed Out',
  SERVER_INTERNAL_ERROR: 'Internal Server Error',
  NETWORK_ERROR: 'Network Unreachable'
};
