import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK} from 'react-admin';
import {apiUrl} from '../common/Globals';
import {HTTP_POST} from '../common/HttpService';

const loginUrl = apiUrl + '/auth/admin/login';
const logoutUrl = apiUrl + '/auth/admin/logout';
const forgotPasswordUrl = apiUrl + '/auth/admin/forgotPassword';
const resetPasswordUrl = apiUrl + '/auth/admin/resetPassword';
const updatePasswordUrl = apiUrl + '/auth/admin/updatePassword';

const login = async (params) => {
  console.log(params);
  const {username, password} = params;

  const body = {
    email: username,
    password: password
  };
  let res = await HTTP_POST(loginUrl, body);

  if (res.success) {
    localStorage.setItem('email', username);
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('role', res.data.role);
    return Promise.resolve();
  } else {
    // loginCallback(res.data);
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
};

const logout = async () => {
  let email = localStorage.getItem('email');
  let token = localStorage.getItem('token');
  const body = {
    email,
    token
  };

  if (email === null) return Promise.resolve();

  let res = await HTTP_POST(logoutUrl, body);

  if (res.success) {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
  }
  return Promise.resolve();
};

const authError = (params) => {
  console.log(' AUTH_ERROR params ', params);
  const {status} = params;
  if (status === 401 || status === 403) {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    return Promise.reject();
  }
  return Promise.resolve();
};

const authCheck = (params) => {
  console.log(' AUTH_CHECK params ', params);
  return localStorage.getItem('email') && localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
};

const forgotPassword = async (params) => {
  console.log(params);
  const {email} = params;

  const body = {
    email
  };
  let res = await HTTP_POST(forgotPasswordUrl, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const resetPassword = async (params) => {
  const {resetToken} = params;
  const body = {
    resetToken
  };
  let res = await HTTP_POST(resetPasswordUrl, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const updatePassword = async (params) => {
  const {resetToken, password} = params;
  const body = {
    resetToken,
    password
  };
  let res = await HTTP_POST(updatePasswordUrl, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const AuthProvider = async (type, params) => {
  // called when the user attempts to log in
  console.log('AuthProvider: Type', type);
  switch (type) {
    case AUTH_LOGIN:
      return login(params);
    case AUTH_LOGOUT:
      return logout(params);
    case AUTH_ERROR:
      return authError(params);
    case AUTH_CHECK:
      return authCheck(params);
    case 'FORGOT_PASSWORD':
      return forgotPassword(params);
    case 'RESET_PASSWORD':
      return resetPassword(params);
    case 'UPDATE_PASSWORD':
      return updatePassword(params);
    default:
      return Promise.reject('Unknown method');
  }
};

export default AuthProvider;
