import TrainingIcon from '@material-ui/icons/Poll';
import TrainingRequestsList from './TrainingRequests';
import TrainingRequestsShow from './TrainingRequestsShow';
import TrainingRequestsEdit from './TrainingRequestsEdit';

const TrainingRequests = {
  list: TrainingRequestsList,
  show: TrainingRequestsShow,
  edit: TrainingRequestsEdit,
  icon: TrainingIcon
};
export default TrainingRequests;
