import WorkIcon from '@material-ui/icons/Work';
import VendorEdit from './VendorEdit';
import VendorList from './Vendors';
import VendorShow from './VendorShow';
import VendorCreate from './VendorCreate';

const Vendors = {
  create: VendorCreate,
  edit: VendorEdit,
  list: VendorList,
  show: VendorShow,
  icon: WorkIcon
};
export default Vendors;
