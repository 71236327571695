import React from 'react';
import 'react-phone-number-input/style.css';

import {Create, FormTab, TabbedForm, TextInput, email, required, CardActions} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544},
  image: {
    maxHeight: '70px',
    maxWidth: '70px',
    borderRadius: '30%'
  }
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const UserCreate = ({classes, ...props}) => (
  <Create {...props} title="Create  Vendor" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Create  Vendor" path="">
        <TextInput autoFocus source="name" label="Name" validate={required()} formClassName={classes.first_name} />
        <TextInput type="email" source="email" validate={[required(), email()]} formClassName={classes.email} />
        <TextInput type="tel" source="phone" formClassName={classes.email} />
        <TextInput source="serviceArea" formClassName={classes.serviceArea} />
        <TextInput source="reqcount" formClassName={classes.reqcount} label="No. of Requests" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(UserCreate);
