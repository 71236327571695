import {ShowController} from 'ra-core';
import React from 'react';
import {ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';
const AccountShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="id" />
            <TextField source="jobId" label="Job Id" />
            <TextField source="vendorId" label="Vendor Id" />
            <TextField source="customerId" label="Customer Id" />

            <TextField source="modeOfPayment" label="Payment Mode" />
            <TextField source="bankTransactionId" label="Bank Transaction Id" />
            <TextField source="internalTransactionId" label="Internal Transaction Id" />

            <TextField source="amountReceived" label="Total Service amount" />
            <TextField source="commissionAmount" label="Commission Amount" />
            <TextField source="vendorEarning" label="Vendor Chargeable amount" />

            <TextField source="commissionPercentage" label="Commission Percentage" />
            <TextField source="commissionPaymentStatus" label="Commission Payment Status" />
            <TextField source="commissionTransactionID" label="Commission Transaction Id" />

            <TextField source="date" label="date" />
            <TextField source="serviceCategory" label="Service Category" />
            <TextField source="paymentStatus" label="Payment Status" />

            <TextField source="transactionId" label="Transaction Id" />
            <TextField source="mihPayId" label="mih Pay Id" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default AccountShow;
