/* eslint react/jsx-key: off */
import React from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  //   ReferenceInput,
  SelectInput
  //   ImageField,
  //   TextField,
  //   Tab,
  //   FileField,
  //   SingleFieldList,
  //   ArrayInput,
  //   ChipField,
  //   SimpleFormIterator,
  //   ArrayField,
  //   Datagrid,
  //   required
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import {withStyles} from '@material-ui/core';
export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};
const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TaxiEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

const currentStatusList = [
  {id: 'pending approval', name: 'pending approval'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'approved', name: 'approved'}
];

const Title = ({record}) => {
  return <span>Edit: {record ? `${record.rcownername}` : ''} </span>;
};
const TaxiEdit = ({...props}) => (
  <Edit title={<Title />} {...props}>
    <TabbedForm toolbar={<TaxiEditToolbar />}>
      <FormTab label="Taxi Details">
        <TextInput disabled source="id" />
        <TextInput source="vendorDetails[0].vendorName" label="Vendor Name" />
        <TextInput source="Type" label="Taxi Type" />
        <TextInput source="rcownername" label="RC Owner Name" />
        <TextInput source="platenumber" label="Register Number" />
        <TextInput source="badge" label="Badge Number" />
        <TextInput source="badgearea" label="Badge Area" />
        <TextInput source="insurancenumber" label="Insurance Number" />
        <TextInput source="insurancedate" label="Insurance Expiry Date" />
        <TextInput source="capacity" label="Passenger Capacity" />
        <TextInput source="servicecharge" label="Service Charge (per Kilometer)" />
        <TextInput source="description" label="Description" />
        <SelectInput source="currentStatus" choices={currentStatusList} />
      </FormTab>
    </TabbedForm>
  </Edit>
);

TaxiEdit.propTypes = {};

export default withStyles(styles)(TaxiEdit);
