import ProjectIcon from '@material-ui/icons/AccountTree';
import ProjectRequestsEdit from './ProjectRequestsEdit';
import ProjectRequestsList from './ProjectRequests';
import ProjectRequestsShow from './ProjectRequestsShow';

const ProjectRequests = {
  edit: ProjectRequestsEdit,
  list: ProjectRequestsList,
  show: ProjectRequestsShow,
  icon: ProjectIcon
};
export default ProjectRequests;
