/* eslint react/jsx-key: off */
import React from 'react';
// import {apiUrl} from '../../common/Globals';
// import {HTTP_GET, HTTP_POST} from '../../common/HttpService';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  ImageField,
  ImageInput
  //img
} from 'react-admin';
import {withStyles} from '@material-ui/core';
import {styles} from './ServiceCreate';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ServiceEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

// const PreviewImage = ({record}) => <img width={250} src={record} alt="Image Preview" />;

const ServiceEdit = ({classes, ...props}) => (
  <Edit title="Edit  Service" {...props}>
    <TabbedForm toolbar={<ServiceEditToolbar />}>
      <FormTab label="Service Details">
        <TextInput disabled source="id" />
        <TextInput
          autoFocus
          source="serviceName"
          label="Service Name"
          validate={required()}
          formClassName={classes.first_name}
        />
        <TextInput disabled source="serviceCategoryName" />
      </FormTab>
      <FormTab label="Images">
        <ImageField source="imagePath" title="imagePath" />
        {/* <ImageField source="imagePath" src="imagePath" label="Service image" /> */}
        <ImageInput
          source="images"
          label="images"
          accept="image/*"
          maxSize={5000000}
          placeholder={
            <p>
              Upload Image
              <span>*File size should not exceed 5MB</span>
            </p>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

ServiceEdit.propTypes = {};

export default withStyles(styles)(ServiceEdit);
