import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, DELETE_MANY} from 'react-admin';

import {HTTP_GET, HTTP_POST, HTTP_DELETE} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/manpower/request';

const getManpowerRequest = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getManpowerRequests = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  console.log('Husain res ', res);
  return res;
};

const createManpowerRequests = async (params) => {
  const url = `${resourceBaseUlr}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const updateManpowerRequests = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteManpowerRequests = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_DELETE(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteMany = async (params) => {
  const url = `${resourceBaseUlr}`;
  const body = params;
  let res = await HTTP_DELETE(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const ManpowerRequestsDataProvider = (type, resource, params) => {
  console.log('Query Type ', type);
  switch (type) {
    case GET_LIST:
      return getManpowerRequests(params);

    case GET_ONE:
      return getManpowerRequest(params);

    case CREATE:
      return createManpowerRequests(params);

    case UPDATE:
      return updateManpowerRequests(params);

    case DELETE:
      return deleteManpowerRequests(params);

    case DELETE_MANY:
      return deleteMany(params);

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default ManpowerRequestsDataProvider;
