import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //EmailField,
  //EditButton,
  Filter,
  TextInput,
  DateInput
} from 'react-admin';

const RentalRequestsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput label="Customer Name" source="customerName" alwaysOn />
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Mobile" source="mobileNumber" alwaysOn />
    <TextInput label="Equipment Type" source="equipmentType" alwaysOn />
    <TextInput label="Brand" source="brand" />
    <TextInput label="Approved Vendor" source="approvedVendorId" />
    <TextInput label="Status" source="status" alwaysOn />
    <TextInput label="Address" source="address" />
    <TextInput label="LocalBody" source="localBody" />
    <TextInput label="District" source="district" />
    <TextInput label="State" source="state" />
    <TextInput label="Pincode" source="pincode" />
    <TextInput label="AmountToBePaid" source="amountToBePaid" />
    <TextInput label="AmountPaid" source="amountPaid" />
    <TextInput label="BalanceAmount" source="balanceAmount" />
    <TextInput label="CreatedAt" source="createdAt" />
    <TextInput label="Approved Vendor" source="approvedVendorId" />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const RentalRequestsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const RentalRequestsList = (props) => (
  <List
    title=" Rental Requests"
    {...props}
    filters={<RentalRequestsFilter />}
    bulkActionButtons={<RentalRequestsBulkActionButtons />}
  >
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="customerName" />
      <TextField source="mobileNumber" label="Mobile" />
      <TextField source="equipmentType" />
      <TextField source="equipmentSpecification" label="Equipment Spec" />
      <TextField source="brand" />
      <TextField source="description" />
      <TextField source="address" />
      <TextField source="localBody" />
      <TextField source="district" />
      <TextField source="state" />
      <TextField source="pincode" />
      <TextField source="localContactNumber" />
      <TextField source="amountToBePaid" label="Total Rent" />
      <TextField source="amountPaid" />
      <TextField source="balanceAmount" />
      <TextField source="approvedVendorId" />
      <TextField source="status" />
      <TextField source="startDate" />
      <TextField source="endDate" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default RentalRequestsList;
