import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {Card, CardActions} from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import {cyan500, pinkA200} from 'material-ui/styles/colors';
import authProvider from './../dataproviders/AuthProvider';
import {Notification, showNotification, translate, userLogin as userLoginAction} from 'react-admin';
import AppBar from 'material-ui/AppBar';
import {required} from 'react-admin';
const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    minWidth: 300
  },
  avatar: {
    margin: '1em',
    textAlign: 'center '
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    display: 'flex'
  },
  hint: {
    textAlign: 'center',
    marginTop: '1em',
    color: '#ccc'
  },
  MuiSnackbarContent: {
    backgroundColor: 'red'
  }
};

function getColorsFromTheme(theme) {
  if (!theme) return {primary1Color: cyan500, accent1Color: pinkA200};
  const {
    palette: {primary1Color, accent1Color}
  } = theme;
  return {primary1Color, accent1Color};
}

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({meta: {touched, error} = {}, input: {...inputProps}, ...props}) => {
  return <TextField errorText={touched && error} {...inputProps} {...props} fullWidth />;

  // eslint-disable-next-line prettier/prettier
  // return <TextField errorText={touched && error} {...inputProps} {...props} fullWidth />;
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  forget = ({email, password}) => {
    let self = this;
    this.setState({submitting: true});
    authProvider('FORGOT_PASSWORD', {email, password})
      .then(() => {
        self.props.showNotification('Password reset mail sent, please check mail ' + email);
      })
      .catch((error) => {
        console.log('error: ', JSON.stringify(error));
        self.setState({submitting: false});
        self.props.showNotification(error);
      });
  };

  render() {
    const {handleSubmit, theme, translate} = this.props;
    const {submitting} = this.state;
    const muiTheme = getMuiTheme(theme);
    const {primary1Color, accent1Color} = getColorsFromTheme(muiTheme);
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          <AppBar title="Forgot Password" />
          <div style={{...styles.main, backgroundColor: primary1Color}}>
            <Card style={styles.card}>
              <div style={styles.avatar}>
                <Avatar backgroundColor={accent1Color} icon={<LockIcon />} size={60} />
              </div>
              <form onSubmit={handleSubmit(this.forget)}>
                <div style={styles.form}>
                  <div style={styles.input}>
                    <Field
                      name="email"
                      component={renderInput}
                      floatingLabelText={'email'}
                      type="email"
                      validate={required()}
                    />
                  </div>
                </div>
                <CardActions>
                  <RaisedButton
                    type="submit"
                    primary
                    disabled={submitting}
                    label={translate('PASSWORD RESET')}
                    fullWidth
                  />
                </CardActions>
              </form>
            </Card>
            <Notification type="warning" autoHideDuration={null} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

ForgotPassword.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  showNotification: PropTypes.func
};

ForgotPassword.defaultProps = {
  theme: {}
};

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const {translate} = props;
      if (!values.email) errors.email = translate('ra.validation.required');
      if (!values.password) errors.password = translate('ra.validation.required');
      return errors;
    }
  }),
  connect(null, {userLogin: userLoginAction, showNotification})
);

export default enhance(ForgotPassword);
