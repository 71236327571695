import React from 'react';
import {AppBar /*UserMenu, MenuItemLink, translate*/} from 'react-admin';
import Typography from '@material-ui/core/Typography';
//import SettingsIcon from '@material-ui/icons/Settings';
import {withStyles} from '@material-ui/core/styles';

import {Avatar} from '@material-ui/core';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  flex: {display: 'flex'},
  flexColumn: {display: 'flex', flexDirection: 'row'}
};

const CustomAppBar = ({classes, ...props}) => (
  <AppBar {...props} /*userMenu={<CustomUserMenu />}*/ title="TeeVeeHire">
    <Avatar src="logo.png" className={classes.flexColumn} />
    <Typography variant="headline" component="h4" className={classes.flex}>
      {`TeeVeeHire e-Commerce and Engineering  Solutions `}
    </Typography>
    <span className={classes.spacer} />
    <Typography variant="title" color="inherit" className={classes.title} id="react-admin-title" />
    <span className={classes.spacer} />
  </AppBar>
);

export default withStyles(styles)(CustomAppBar);
