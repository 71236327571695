import BusinessIcon from '@material-ui/icons/Business';
import CompanyDetailsEdit from './CompanyDetailsEdit';
import CompanyDetailsList from './CompanyDetails';
import CompanyDetailsShow from './CompanyDetailsShow';
import CompanyDetailsCreate from './CompnyDetailsCreate';

const CompanyDetails = {
  create: CompanyDetailsCreate,
  edit: CompanyDetailsEdit,
  list: CompanyDetailsList,
  show: CompanyDetailsShow,
  icon: BusinessIcon
};
export default CompanyDetails;
