import PlaceIcon from '@material-ui/icons/Place';
import JobRequestRadiusList from './JobRequestRadius';
import JobRequestRadiusEdit from './JobRequestRadiusEdit';

const JobRequestRadius = {
  list: JobRequestRadiusList,
  icon: PlaceIcon,
  edit: JobRequestRadiusEdit
};
export default JobRequestRadius;
