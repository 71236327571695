import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //   EditButton,
  //   EmailField,
  Filter,
  TextInput,
  ImageField,
  DateInput
} from 'react-admin';

const ToolsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput source="vendorId" label="Vendor Id" />
    <TextInput source="courseName" label="Course Name" alwaysOn />
    <TextInput source="serviceName" label="ServiceName Name" alwaysOn />
    <TextInput source="fees" label="fees" />
    <TextInput source="duration" label="Duration" />
    <TextInput source="durationPeriod" label="Duration Period" />
    <TextInput source="description" label="Description" />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const ToolsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const ToolsList = ({classes, ...props}) => (
  <List title=" Courses" {...props} filters={<ToolsFilter />} bulkActionButtons={<ToolsBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="courseName" label="Course Name" />
      <TextField source="serviceName" label="ServiceName Name" />
      <TextField source="duration" label="Duration" />
      <TextField source="durationPeriod" label="Duration Period" />
      <TextField source="fees" label="fees" />
      <TextField source="description" label="description" />
      <ImageField source="courseImagePath" label="photo" classes={classes} />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default ToolsList;
