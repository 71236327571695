import {ShowController} from 'ra-core';
import React from 'react';
import {ImageField, SelectInput, FormTab, ShowView, TabbedForm, TextField} from 'react-admin';
const currentStatusList = [
  {id: 'pending approval', name: 'pending approval'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'qualified', name: 'qualified'}
];
// const role = localStorage.getItem('role');
const RentalRequestsDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedForm>
          <FormTab label="Tool Details" path="">
            <TextField source="vendorId" label="Vendor Id" />
            <TextField source="equipmentType" label="Equipment Type" />
            <TextField source="equipmentSpecification" label="Equipment Spec" />
            <TextField source="brand" label="Brand" />
            <TextField source="rent" label="Rental Charge" />
            <TextField source="estimationType" label="Estimation Type" />
            <TextField source="description" label="Description" />
            <SelectInput source="currentStatus" choices={currentStatusList} />
          </FormTab>
          <FormTab label="Images">
            <ImageField source="toolImagePath" label="" />
          </FormTab>
        </TabbedForm>
      </ShowView>
    )}
  </ShowController>
);

export default RentalRequestsDetailsShow;
