import {errorCodes} from './ErrorCodes';

export const HTTP_GET = async (url = false) => {
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => controller.abort(), 15000);

  var jsonResponse = {success: false, statusCode: errorCodes.UNDEFINED, data: ''};

  console.log('HTTP REQUEST: ' + url + ' ...');

  const accessToken = localStorage.getItem('token');

  // Application Headers
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // Add Authorization Header if Present
  if (accessToken != null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  return fetch(url, {
    signal: signal,
    method: 'GET',
    headers: headers
  })
    .then((response) =>
      response.json().then((responseData) => {
        jsonResponse.statusCode = response.status;
        jsonResponse.data = responseData.data;

        if (Array.isArray(jsonResponse.data)) {
          jsonResponse.total = responseData.total || jsonResponse.data.length;
        }
        if (response.status === 200) {
          jsonResponse.success = true;
        }
        console.log(jsonResponse);
        return jsonResponse;
      })
    )
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Request Timedout');

        jsonResponse.statusCode = errorCodes.REQUEST_TIMED_OUT;
        jsonResponse.data = 'Request Timed out. Please try again later';
        return jsonResponse;
      } else {
        console.log('HTTP Error ' + error);

        jsonResponse.statusCode = errorCodes.NETWORK_ERROR;
        jsonResponse.data = error;

        return jsonResponse;
      }
    });
};

export const HTTP_POST = async (url, body) => {
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => controller.abort(), 15000);

  var jsonResponse = {success: false, statusCode: errorCodes.UNDEFINED, data: ''};

  const accessToken = localStorage.getItem('token');

  // Application Headers
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // Add Authorization Header if Present
  if (accessToken != null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  console.log('[HTTP REQUEST]: ' + JSON.stringify({url: url, body: body}));

  return fetch(url, {
    signal: signal,
    method: 'POST',
    //mode: 'no-cors',
    headers: headers,
    body: JSON.stringify(body)
  })
    .then((response) =>
      response.json().then((responseData) => {
        jsonResponse.statusCode = response.status;
        jsonResponse.data = responseData.data;

        if (Array.isArray(jsonResponse.data)) {
          jsonResponse.total = jsonResponse.data.length;
        }

        if (response.status === 200) {
          jsonResponse.success = true;
        }
        console.log('[HTTP_RESPONSE]: ' + JSON.stringify(jsonResponse));
        return jsonResponse;
      })
    )
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Request Timedout');

        jsonResponse.statusCode = errorCodes.REQUEST_TIMED_OUT;
        jsonResponse.data = 'Request Timed out. Please try again later';
        console.log('[HTTP_RESPONSE]: ' + JSON.stringify(jsonResponse));
        return jsonResponse;
      } else {
        console.log('HTTP Error ' + error);

        jsonResponse.statusCode = errorCodes.NETWORK_ERROR;
        jsonResponse.data = error;
        console.log('[HTTP_RESPONSE]: ' + JSON.stringify(jsonResponse));
        return jsonResponse;
      }
    });
};

export const HTTP_DELETE = async (url, body) => {
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => controller.abort(), 15000);

  var jsonResponse = {success: false, statusCode: errorCodes.UNDEFINED, data: ''};

  const accessToken = localStorage.getItem('token');

  // Application Headers
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // Add Authorization Header if Present
  if (accessToken != null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  console.log('[HTTP REQUEST]: ' + JSON.stringify({url: url, body: body}));

  return fetch(url, {
    signal: signal,
    method: 'DELETE',
    //mode: 'no-cors',
    headers: headers,
    body: JSON.stringify(body)
  })
    .then((response) =>
      response.json().then((responseData) => {
        jsonResponse.statusCode = response.status;
        jsonResponse.data = responseData.data;

        if (Array.isArray(jsonResponse.data)) {
          jsonResponse.total = jsonResponse.data.length;
        }

        if (response.status === 200) {
          jsonResponse.success = true;
        }

        return jsonResponse;
      })
    )
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Request Timedout');

        jsonResponse.statusCode = errorCodes.REQUEST_TIMED_OUT;
        jsonResponse.data = 'Request Timed out. Please try again later';
        return jsonResponse;
      } else {
        console.log('HTTP Error ' + error);

        jsonResponse.statusCode = errorCodes.NETWORK_ERROR;
        jsonResponse.data = error;

        return jsonResponse;
      }
    });
};
