import * as React from 'react';
import CardWithIcon from './CardWithIcon';

const ResourceCountCard = ({path, resource, count, icon}) => {
  //const { value } = props;
  //const translate = useTranslate();
  return <CardWithIcon to={path ? `${path}` : '.'} icon={icon} title={resource} subtitle={count} />;
};

export default ResourceCountCard;
