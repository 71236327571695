import React from 'react';
import {List, Datagrid, TextField, EditButton} from 'react-admin';

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};
export const NotificationList = (props) => (
  <List title=" Notifications" {...props}>
    <Datagrid {...props}>
      <TextField source="notName" label="Notification type" />
      <TextField source="notContent" />
      <TextField source="notUrl" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default NotificationList;
