import React from 'react';
import {
  List,
  //   BulkDeleteWithConfirmButton,
  Datagrid,
  TextField
  //   EditButton,
  //   EmailField,
  //   TextInput
  //   Filter,
} from 'react-admin';

import VendorTransactions from './VendorTransactions';

// const AdminFilter = (props) => (
//   <Filter {...props}>
//     {/* <TextInput label="Search" source="q" alwaysOn /> */}
//     <TextInput label="Email" source="email" />
//     <TextInput label="Name" source="name" />
//   </Filter>
// );

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

// const AdminBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const AccountList = (props) => (
  <List title=" Accounts Summary" {...props}>
    <Datagrid rowClick={rowClick} /*expand={<AdminEdit/> }*/ {...props}>
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="vendorName" label="Vendor Name" />
      <TextField source="amountReceived" label="Total Service amount" />
      <TextField source="commissionAmount" label="Commission Amount" />
      <TextField source="vendorEarning" label="Vendor Chargeable amount" />
      <VendorTransactions label="Transactions" fieldName={'vendorId'} />
    </Datagrid>
  </List>
);
export default AccountList;
