import React from 'react';
import {List, Datagrid, TextField, Filter, TextInput, DateInput} from 'react-admin';

const AdminFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput source="jobId" label="Job Id" alwaysOn />
    <TextInput source="vendorId" label="Vendor Id" alwaysOn />
    <TextInput source="customerId" label="Customer Id" alwaysOn />
    <TextInput source="modeOfPayment" label="Mode Of Payment" alwaysOn />
    <TextInput source="bankTransactionId" label="Bank Transaction Id" alwaysOn />
    <TextInput source="commissionPercentage" label="Commission Percentage" alwaysOn />
    <TextInput source="commissionPaymentStatus" label="CommissionPaymentStatus" alwaysOn />
    <TextInput source="serviceCategory" label="Service Category" alwaysOn />
    <TextInput source="paymentStatus" label="Payment Status" alwaysOn />
    <TextInput source="amountReceived" label="Total Service amount" alwaysOn />
    <TextInput source="commissionAmount" label="Commission Amount" alwaysOn />
    <TextInput source="vendorEarning" label="Vendor Chargeable amount" alwaysOn />
  </Filter>
);

const rowClick = () => {
  // if (record.id === 0) {
  //   return 'show';
  // }
  // return 'edit';
  return 'show';
};

// const ShowEditButton = ({record, basePath}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

// const ShowEditButton = ({record, basePath}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

export const AccountList = (props) => (
  <List title=" Accounts" {...props} filters={<AdminFilter />}>
    <Datagrid rowClick={rowClick} /*expand={<AdminEdit/> }*/ {...props}>
      {/*<TextField source="id" />*/}
      <TextField source="jobId" label="Job Id" />
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="customerId" label="Customer Id" />

      <TextField source="modeOfPayment" label="Payment Mode" />
      <TextField source="bankTransactionId" label="Bank Transaction Id" />
      <TextField source="internalTransactionId" label="Internal Transaction Id" />

      <TextField source="amountReceived" label="Total Service amount" />
      <TextField source="commissionAmount" label="Commission Amount" />
      <TextField source="vendorEarning" label="Vendor Chargeable amount" />

      <TextField source="commissionPercentage" label="Commission Percentage" />
      <TextField source="commissionPaymentStatus" label="Commission Payment Status" />
      <TextField source="commissionTransactionID" label="Commission Transaction Id" />

      <TextField source="date" label="date" />
      <TextField source="serviceCategory" label="Service Category" />
      <TextField source="paymentStatus" label="Payment Status" />

      <TextField source="transactionId" label="Transaction Id" />
      <TextField source="mihPayId" label="mih Pay Id" />
    </Datagrid>
  </List>
);
export default AccountList;
