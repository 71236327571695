import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE, UPDATE} from 'react-admin';

import {HTTP_GET, HTTP_POST} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/rental/request';

const getRentalRequest = async (params) => {
  console.log('GET_ONE_RentalREQUEST');
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getRentalRequests = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const updateRentalReques = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const RentalRequestsDataProvider = (type, resource, params) => {
  console.log('Query Type ', type);
  switch (type) {
    case GET_LIST:
      return getRentalRequests(params);

    case GET_ONE:
      return getRentalRequest(params);

    case UPDATE:
      return updateRentalReques(params);

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default RentalRequestsDataProvider;
