import React from 'react';
import compose from 'recompose/compose';
import {/*Avatar, Box, */ Card, CardContent /* CardActions, Button,*/, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
// import Project from './Project';
// import Manpower from './Manpower';
// import Rental from './Rental';
// import Training from './Training';
// import {Link} from 'react-router-dom';
// import {RESOURCES} from '../common/Resources';

const styles = {
  media: {
    height: '18em'
  },
  title: {
    fontWeight: 'bold',
    fontColor: 'blue',
    padding: '0'
  },
  backgrougd: {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${'logo.jpeg'})`
  },
  flexColumn: {display: 'flex', flexDirection: 'column'},
  leftCol: {flex: 1, marginRight: '1em'},
  rightCol: {flex: 1, marginLeft: '1em'},
  singleCol: {marginBottom: '0.5em'}
};

const Welcome = ({classes /*, translate */}) => (
  <div className={classes.main}>
    <Card>
      <CardContent>
        <Typography className={classes.title} color="textSecondary">
          {'Admin Dashboard'}
        </Typography>
      </CardContent>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Welcome);
