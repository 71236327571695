/* eslint react/jsx-key: off */
import React, {useState} from 'react';
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  //ReferenceInput,
  SelectInput,
  ImageField,
  //TextField,
  //Tab,
  FileField,
  SingleFieldList,
  //ArrayInput,
  ChipField,
  //SimpleFormIterator,
  ArrayField,
  //Datagrid,
  required
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import {withStyles} from '@material-ui/core';
import {styles} from './VendorCreate';
//import {HTTP_GET} from '../../common/HttpService';
// import {HTTP_GET} from '../../common/HttpService.js';
//import SimpleChipField from './SimpleChipField';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const VendorEditToolbar = withStyles(toolbarStyles)(({...props}) => (
  <Toolbar {...props}>
    <SaveButton />
    {props.record.id && <DeleteWithConfirmButton />}
  </Toolbar>
));

const currentStatusList = [
  {id: 'request_pending', name: 'request_pending'},
  {id: 'disqualified', name: 'disqualified'},
  {id: 'qualified', name: 'qualified'}
];

const status = [
  {id: 'request_pending', name: 'request_pending'},
  {id: 'rejected', name: 'Rejected'},
  {id: 'active', name: 'Active'}
];

const manpow = [
  {id: 'request_pending', name: 'request_pending'},
  {id: 'not_Approved', name: 'not Approved'},
  {id: 'active', name: 'Approved'}
];

const Title = ({record}) => {
  return <span>Edit: {record ? `${record.vendorName}` : ''} </span>;
};
const VendorEdit = ({...props}) => {
  const [currentStatus, setCurrentStatus] = useState('');
  const [rentalStatus, setrentalStatus] = useState('');
  const [manpowerStatus, setmanpowerStatus] = useState('');
  const [trainerStatus, settrainerStatus] = useState('');
  const [projectStatus, setprojectStatus] = useState('');

  const handleStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };
  // useEffect(() => {

  // }, []);
  return (
    <Edit title={<Title />} {...props}>
      <TabbedForm toolbar={<VendorEditToolbar />}>
        <FormTab label="Vendor Details">
          <TextInput disabled source="id" />
          <TextInput source="vendorName" />
          <TextInput source="phone" />
          <TextInput source="email" />
          <TextInput source="serviceName" />
          {/* <TextInput source="professions[0].Project" /> */}
          <ArrayField source="profession">
            <SingleFieldList linkType={false}>
              <ChipField source="key" />
            </SingleFieldList>
          </ArrayField>
          {/*<ArrayInput source="profession">*/}
          {/*  <SimpleFormIterator>*/}
          {/*    <TextInput source="key" />*/}
          {/*  </SimpleFormIterator>*/}
          {/*</ArrayInput>*/}
          <TextInput disabled source="qualification" />
          <TextInput disabled source="type" />
          <TextInput disabled source="gender" />
          <TextInput disabled source="yearOfExperience" />
        </FormTab>
        <FormTab label="Status">
          {/* <TextInput disabled source="currentStatus" /> */}
          <TextInput disabled source="workingStatus" />
          <TextInput disabled source="currentUserRole" />
          {/* <TextInput disabled source="qualifiedDate" />
        <TextInput disabled source="manpowerStatus" />
        <TextInput disabled source="rentalStatus" />
        <TextInput disabled source="projectStatus" />
        <TextInput disabled source="trainerStatus" /> */}
          <TextInput validate={required()} source="profitPercentage" />
          {/* <SelectInput optionText="currentStatus" /> */}
          <SelectInput source="currentStatus" choices={currentStatusList} />
          {/* <ReferenceInput
          source="currentStatus"
          label="current vendor Status"
          reference="currentStatusList"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}
          {/* <RichTextInput source="disqualifiedReason" label="Reason for vendor disqualification" /> */}
        </FormTab>
        {manpowerStatus != 'NA' && (
          <FormTab label="Manpower status">
            <TextInput disabled source="vendorName" />
            <SelectInput source="manpowerStatus" onChange={handleStatusChange} choices={status} />
          </FormTab>
        )}

        {rentalStatus != null && (
          <FormTab label="Rental status">
            <TextInput disabled source="rentalStatus" />
            <SelectInput source="rentalStatus" onChange={handleStatusChange} choices={status} />
          </FormTab>
        )}
        {trainerStatus != 'NA' && (
          <FormTab label="Training status">
            <TextInput disabled source="vendorName" />
            <SelectInput source="trainerStatus" onChange={handleStatusChange} choices={status} />
          </FormTab>
        )}
        {trainerStatus != 'NA' && (
          <FormTab label="Project status">
            <TextInput disabled source="vendorName" />
            <SelectInput source="projectStatus" onChange={handleStatusChange} choices={status} />
          </FormTab>
        )}
        <FormTab label="Images">
          <ImageField source="passportImagePath" src="passportImagePath" label="passport image" />
          <ImageField source="liveImagePath" src="liveImagePath" label="live image" />
        </FormTab>
        <FormTab label="Documents">
          <FileField source="cvUploadPath" title="CV" label="" />
          <FileField source="certificateUploadPath" title="certificate" label="" />
        </FormTab>
        <FormTab label="Address">
          <TextInput disabled source="address" />
          <TextInput disabled source="localbody" />
          <TextInput disabled source="district" />
          <TextInput disabled source="state" />
          <TextInput disabled source="pincode" />
          <TextInput disabled source="phone" />
          <TextInput disabled source="email" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

VendorEdit.propTypes = {};

export default withStyles(styles)(VendorEdit);
