import React from 'react';
import {Admin, Resource} from 'react-admin';
import {Route} from 'react-router-dom';

import AuthProvider from './dataproviders/AuthProvider';
//import LoginPage from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import {Dashboard} from './dashboard';
import {Layout} from './layout';

import {RESOURCES} from './common/Resources';

import DataProvider from './dataproviders/DataProvider';

/* Resources */
import Users from './components/users';
import Service from './components/service';
import ServiceCategory from './components/servicecategory';
import Banner from './components/banner';
import Notification from './components/notification';
import Admins from './components/admins';
import CompanyDetails from './components/companydetails';
import ManpowerRequests from './components/manpowerrequests';
import ProjectRequests from './components/projectrequests';
import RentalRequests from './components/rentalrequests';
import TrainingRequests from './components/trainingrequests';
import Vendors from './components/vendors';
import ProjectSpecifications from './components/projectspecifications';
import Units from './components/units';
import Jobrequestradius from './components/jobrequestradius';
import Accounts from './components/accounts';
import AccountSummary from './components/accounts/AccountsSummary';
import Tools from './components/tools';
import Taxi from './components/taxi';
import Truck from './components/truck';
import Course from './components/course';
import ServiceRequests from './components/servicerequests';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
const messages = {
  en: englishMessages
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

const App = () => (
  <Admin
    title={'TeeVeeHire Admin'}
    i18nProvider={i18nProvider}
    locale="en"
    dataProvider={DataProvider}
    dashboard={Dashboard}
    appLayout={Layout}
    //loginPage={LoginPage}
    authProvider={AuthProvider}
    customRoutes={[
      <Route path="/forgotPassword" component={ForgotPassword} noLayout />,
      <Route path="/resetPassword/:resetToken" component={ResetPassword} noLayout />
    ]}
  >
    <Resource name={RESOURCES.ADMINS} {...Admins} options={{label: 'Admins'}} />
    <Resource name={RESOURCES.ACCOUNTS} {...Accounts} options={{label: 'Accounts'}} />
    <Resource name={RESOURCES.ACCOUNTS_SUMMARY} list={AccountSummary} options={{label: 'Vendor Transaction Summary'}} />
    <Resource name={RESOURCES.COMPANYDETAILS} {...CompanyDetails} options={{label: 'Company Details'}} />
    <Resource name={RESOURCES.SERVICE} {...Service} options={{label: 'Services'}} />
    <Resource name={RESOURCES.SERVICECATEGORY} {...ServiceCategory} options={{label: 'Service Category'}} />
    <Resource name={RESOURCES.BANNER} {...Banner} options={{label: 'Banner'}} />
    <Resource name={RESOURCES.NOTIFICATION} {...Notification} options={{label: 'Notification'}} />
    <Resource name={RESOURCES.USERS} {...Users} options={{label: 'Users'}} />
    <Resource name={RESOURCES.JOBREQUESTRADIUS} {...Jobrequestradius} options={{label: 'Job Request Radius'}} />
    <Resource name={RESOURCES.MANPOWERREQUESTS} {...ManpowerRequests} options={{label: 'Manpower'}} />
    <Resource name={RESOURCES.PROJECTREQUESTS} {...ProjectRequests} options={{label: 'Project'}} />
    <Resource name={RESOURCES.RENTALREQUESTS} {...RentalRequests} options={{label: 'Rental'}} />
    <Resource name={RESOURCES.TRAININGREQUESTS} {...TrainingRequests} options={{label: 'Training'}} />
    <Resource name={RESOURCES.VENDORS} {...Vendors} options={{label: 'Vendors'}} />
    <Resource name={RESOURCES.TOOLS} {...Tools} options={{label: 'Tools'}} />
    <Resource name={RESOURCES.TAXI} {...Taxi} options={{label: 'Taxi'}} />
    <Resource name={RESOURCES.TRUCK} {...Truck} options={{label: 'Truck'}} />
    <Resource name={RESOURCES.COURSES} {...Course} options={{label: 'Courses'}} />
    <Resource
      name={RESOURCES.PROJECTSPECIFICATIONS}
      {...ProjectSpecifications}
      options={{label: 'Project Specifications'}}
    />
    <Resource name={RESOURCES.UNITS} {...Units} options={{label: 'Units'}} />
    <Resource name={RESOURCES.SERVICEREQUESTS} {...ServiceRequests} options={{label: 'Service Requests'}} />
  </Admin>
);

export default App;
