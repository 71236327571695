import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {GET_LIST, GET_ONE, UPDATE} from 'react-admin';

import {HTTP_GET, HTTP_POST /*, HTTP_DELETE */} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/course';

const getCourse = async (params) => {
  console.log('GET_ONE_RentalREQUEST');
  const {id} = params;
  const url = `${resourceBaseUlr}/id/${id}`;
  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getCourses = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const CourseUpdate = async (params) => {
  console.log('------------------------>>>>>>>', params['id']);
  //   const {id} = params['id'];
  const url = `${resourceBaseUlr}/update/${params['id']}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};
const CourseDataProvider = (type, resource, params) => {
  console.log('Query Type--|| ', type);
  switch (type) {
    case GET_LIST:
      return getCourses(params);

    case GET_ONE:
      return getCourse(params);
    case UPDATE:
      return CourseUpdate(params);
    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default CourseDataProvider;
