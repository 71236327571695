import {apiUrl} from '../common/Globals';
import {stringify} from 'query-string';
import {fileUploadBase64Payload} from '../common/Utils';
import {GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, DELETE_MANY, GET_MANY} from 'react-admin';

import {HTTP_GET, HTTP_POST, HTTP_DELETE} from '../common/HttpService';

const resourceBaseUlr = apiUrl + '/banners';
// const base_url = 'http://localhost:8080/';
export const getBanner = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_GET(url);
  res.data.bannerUrl;
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getManyBanners = async (params) => {
  const query = {
    filter: JSON.stringify({id: params.ids})
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const getBanners = async (params) => {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;

  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    filter: JSON.stringify(params.filter)
  };
  const url = `${resourceBaseUlr}?${stringify(query)}`;

  let res = await HTTP_GET(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const createBanner = async (params) => {
  const url = `${resourceBaseUlr}`;
  let ImgUrl = '';
  console.log('dddddddddd', params);
  if (params.data.images && params.data.images.title) {
    const imageUploadParams = {};
    const imagesss = await fileUploadBase64Payload([params.data.images], 'image');
    console.log('fileUploadBase64Payload imagesss ', imagesss);
    imageUploadParams.data = {};
    imageUploadParams.data.payloads = imagesss;
    imageUploadParams.data.resource = 'banner';
    imageUploadParams.data.resourceName = params.data.bannerName ? params.data.bannerName : '';
    imageUploadParams.data.id = 1;
    const url = apiUrl + '/upload';
    const body = imageUploadParams.data;
    let res = await HTTP_POST(url, body);
    if (res.success && res.data) {
      console.log('image upload response', res.data);
      ImgUrl = res.data;
    }
  }
  params.data.bannerUrl = ImgUrl[0];
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const updateBanner = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  const body = params.data;
  let res = await HTTP_POST(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteBanner = async (params) => {
  const {id} = params;
  const url = `${resourceBaseUlr}/${id}`;
  let res = await HTTP_DELETE(url);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const deleteMany = async (params) => {
  const url = `${resourceBaseUlr}`;
  const body = params;
  let res = await HTTP_DELETE(url, body);
  if (!res.success) {
    return new Promise(function (resolve, reject) {
      reject({message: res.data});
    });
  }
  return res;
};

const BannerDataProvider = (type, resource, params) => {
  console.log('Query Type ', type);
  console.log('Query Params ', params);
  switch (type) {
    case GET_LIST:
      return getBanners(params);

    case GET_MANY:
      return getManyBanners(params);

    case GET_ONE:
      return getBanner(params);

    case CREATE:
      return createBanner(params);

    case UPDATE:
      return updateBanner(params);

    case DELETE:
      return deleteBanner(params);

    case DELETE_MANY:
      return deleteMany(params);

    default:
      console.log('Unknown Type ', type);
      return Promise.reject('Unknown Type');
  }
};

export default BannerDataProvider;
