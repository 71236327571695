const convertFileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => {
      resolve({title: file.title || file.rawFile.name, data: reader.result});
    };
    reader.onerror = reject;
  });
};
export const fileUploadBase64Payload = async (uploads, type) => {
  if (uploads && uploads.length) {
    // only freshly dropped pictures are instance of File
    const formerUploads = uploads.filter((f) => !(f.rawFile instanceof File));
    const newUploads = uploads.filter((f) => f.rawFile instanceof File);
    let transformedNewUploads = [];
    for (let f of newUploads) {
      let base64UploadFile = await convertFileToBase64(f);
      if (base64UploadFile) {
        transformedNewUploads.push({src: base64UploadFile.data, title: base64UploadFile.title, type: type});
      }
    }
    return [...transformedNewUploads, ...formerUploads];
  }
};
