import React from 'react';
import {List, BulkDeleteWithConfirmButton, Datagrid, TextField, EditButton, Filter, TextInput} from 'react-admin';

const ProjectSpecificationsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput label="Specification Name" source="specificationName" alwaysOn />
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Geometry" source="geometry" alwaysOn />
    <TextInput label="unit" source="unit" alwaysOn />
  </Filter>
);

// const rowClick = (id, basePath, record) => {
//   return 'show';
// };

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};

const ProjectSpecificationsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const ProjectSpecificationsList = (props) => (
  <List
    title=" Project Specifications"
    {...props}
    filters={<ProjectSpecificationsFilter />}
    bulkActionButtons={<ProjectSpecificationsBulkActionButtons />}
  >
    <Datagrid /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="specificationName" />
      <TextField source="serviceName" />
      <TextField source="geometry" />
      <TextField source="unit" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default ProjectSpecificationsList;
