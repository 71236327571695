import React from 'react';
import 'react-phone-number-input/style.css';
// import {apiUrl} from '../../common/Globals';
// import {HTTP_GET} from '../../common/HttpService';
import getVendors from '../../dataproviders/Vendors';
import {
  Create,
  FormTab,
  TabbedForm,
  //TextField,
  TextInput,
  FormDataConsumer,
  //AutocompleteInput,
  ReferenceInput,
  //ImageField,
  //ImageInput,
  SelectInput,
  required,
  CardActions
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';
export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const estimationTypeList = [
  {id: 'hours', name: 'hours'},
  {id: 'days', name: 'days'},
  {id: 'months', name: 'months'},
  {id: 'years', name: 'years'}
];
const ToolCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Tool" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Tool Details" path="">
        <ReferenceInput source="Vendors" label="Vendor Name" reference="vendors" validate={[required()]}>
          <SelectInput optionText="vendorName" />
        </ReferenceInput>
        <FormDataConsumer>
          {({formData}) => {
            console.log('formData--------->', formData);
            // if (formData.vendorId) {
            getVendors({id: formData.vendorId})
              .then(function (response) {
                // Check if response contains vendorName property
                console.log('response------>', response);
                if (response.data && response.data.vendorName) {
                  // Update formData with vendorName
                  formData.vendorName = response.data.vendorName;
                }
              })
              .catch(function (error) {
                console.error('Error fetching vendor details:', error);
              });
            // } else {
            //   console.error('Error fetching ------------------>:', formData);
            // }
          }}
        </FormDataConsumer>
        <SelectInput source="estimationType" choices={estimationTypeList} />
        <TextInput
          autoFocus
          source="equipmentSpecification"
          label="Equipment Spec"
          validate={required()}
          formClassName={classes.equipmentSpecification}
        />
        <TextInput
          autoFocus
          source="equipmentType"
          label="Equipment Type"
          validate={required()}
          formClassName={classes.equipmentType}
        />
        <TextInput
          autoFocus
          source="brand"
          label="Equipment Brand"
          validate={required()}
          formClassName={classes.brand}
        />
        <TextInput
          autoFocus
          source="rent"
          label="Equipment Rent "
          validate={required()}
          formClassName={classes.brand}
        />
        <TextInput autoFocus source="description" label="Description" formClassName={classes.description} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(ToolCreate);
