import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  EmailField,
  //EditButton,
  Filter,
  TextInput,
  ImageField,
  //TabbedShowLayout,
  DateInput
} from 'react-admin';
//import UserShow from './UserShow';
import withStyles from '@material-ui/core/styles/withStyles';
const UserFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Phone Number" source="phone" alwaysOn />
    <TextInput label="User Id" source="userId" />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Local Body" source="localBody" />
    <TextInput label="District" source="district" />
    <TextInput label="State" source="state" />
    <TextInput label="Pincode" source="pincode" />
    <TextInput label="Rating" source="rating" />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const UserBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

const styles = {
  image: {
    maxHeight: '50px',
    maxWidth: '50px',
    borderRadius: '30%'
  }
};

export const UserList = ({classes, ...props}) => (
  <List title=" Customer" {...props} filters={<UserFilter />} bulkActionButtons={<UserBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserShow />}*/ {...props}>
      <TextField source="userId" label="Customer Id" />
      <TextField source="name" label="Name" />
      <TextField source="userId" />
      <TextField type="tel" source="phone" />
      <EmailField source="email" />
      <ImageField source="profileImagePath" label="photo" classes={classes} />
      <TextField source="rating" />
      <TextField source="address" />
      <TextField source="district" />
      <TextField source="state" />
      <TextField source="localBody" />
      <TextField source="pincode" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default withStyles(styles)(UserList);
//ShowEditButton />
