// Enum to define all the resources
export const RESOURCES = {
  DASHBOARD: 'dashboard',
  ADMINS: 'admins',
  USERS: 'users',
  SERVICE: 'service',
  SERVICECATEGORY: 'serviceCategory',
  BANNER: 'banner',
  NOTIFICATION: 'notification',
  COMPANYDETAILS: 'companydetails',
  MANPOWERREQUESTS: 'manpowerrequests',
  VENDORS: 'vendors',
  PROJECTSPECIFICATIONS: 'projectspecifications',
  UNITS: 'units',
  JOBREQUESTRADIUS: 'Jobrequestradius',
  PROJECTREQUESTS: 'projectrequests',
  RENTALREQUESTS: 'rentalrequests',
  TRAININGREQUESTS: 'trainingrequests',
  TOOLS: 'tools',
  TAXI: 'taxis',
  TRUCK: 'trucks',
  COURSES: 'course',
  ACCOUNTS: 'accounts',
  ACCOUNTS_SUMMARY: 'accounts_summary',
  SERVICEREQUESTS: 'servicerequests'
};
