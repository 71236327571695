import React from 'react';
import {List, Datagrid, TextField, EditButton} from 'react-admin';

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};

export const JobRequestRadiusList = (props) => (
  <List title=" Job Request Radius" {...props}>
    <Datagrid {...props}>
      <TextField source="serviceCategoryName" label="Service Category Name" />
      <TextField source="distance" label="Distance" />
      <TextField source="unit" label="Unit" />
      <ShowEditButton />
    </Datagrid>
  </List>
);
export default JobRequestRadiusList;
