import AssistantIcon from '@material-ui/icons/Assistant';
import BannerCreate from './BannerCreate';
import BannerEdit from './BannerEdit';
import BannerList from './banner';

const Banner = {
  create: BannerCreate,
  list: BannerList,
  edit: BannerEdit,
  icon: AssistantIcon
};
export default Banner;
