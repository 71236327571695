import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import {withRouter} from 'react-router-dom';
import {DashboardMenuItem, MenuItemLink, Responsive} from 'react-admin';
import SubMenu from './SubMenu';

import Users from '../components/users';
import AdminUsers from '../components/admins';
import Accounts from '../components/accounts';
import Tools from '../components/tools';
import Taxi from '../components/taxi';
import Truck from '../components/truck';
import Course from '../components/course';
import CompanyDetails from '../components/companydetails';
import Service from '../components/service';
import ServiceCategory from '../components/servicecategory';
import Jobrequestradius from '../components/jobrequestradius';
import ManpowerRequests from '../components/manpowerrequests';
import ProjectRequests from '../components/projectrequests';
import RentalRequests from '../components/rentalrequests';
import TrainingRequests from '../components/trainingrequests';
import Vendors from '../components/vendors';
import ProjectSpecifications from '../components/projectspecifications';
import Units from '../components/units';
import ServiceRequests from '../components/servicerequests';
class Menu extends Component {
  state = {
    menuUsers: false,
    menuVendors: false,
    menuAccounts: false,
    menuProjectSettings: false,
    menuManpowerrequests: false,
    menuProjectrequests: false,
    menuRentalrequests: false,
    menuTrainingrequests: false
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object
  };
  handleToggle = (menu) => {
    this.setState((state) => ({[menu]: !state[menu]}));
  };

  render() {
    const role = localStorage.getItem('role');
    const {onMenuClick, open, logout} = this.props;
    return (
      <div>
        <DashboardMenuItem onClick={onMenuClick} />
        <MenuItemLink to="/admins" disabled={role != 'admin'} primaryText="Admins" leftIcon={<AdminUsers.icon />} />
        <SubMenu
          handleToggle={() => this.handleToggle('menuAccounts')}
          isOpen={this.state.menuAccounts}
          sidebarIsOpen={open}
          name="Accounts    &#x25BA;"
          icon={<Accounts.icon />}
        >
          <MenuItemLink
            to={`/accounts_summary`}
            primaryText={`Vendor Summary`}
            leftIcon={<Accounts.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/accounts`}
            primaryText={`Transaction All`}
            leftIcon={<Accounts.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/accounts?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<Accounts.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/accounts?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<Accounts.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuUsers')}
          isOpen={this.state.menuUsers}
          sidebarIsOpen={open}
          name="Customers    &#x25BA;"
          icon={<Users.icon />}
        >
          <MenuItemLink to={`/users`} primaryText={`All`} leftIcon={<Users.icon />} onClick={onMenuClick} />
          <MenuItemLink
            to={`/users?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<Users.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/users?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<Users.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuVendors')}
          isOpen={this.state.menuVendors}
          sidebarIsOpen={open}
          name="Vendors   &#x25BA;"
          icon={<Vendors.icon />}
        >
          <MenuItemLink to={`/vendors`} primaryText={`All`} leftIcon={<Vendors.icon />} onClick={onMenuClick} />
          <MenuItemLink
            to={`/vendors?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<Vendors.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/vendors?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<Vendors.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuManpowerrequests')}
          isOpen={this.state.menuManpowerrequests}
          sidebarIsOpen={open}
          name="ManPower Requests   &#x25BA;"
          icon={<Vendors.icon />}
        >
          <MenuItemLink to="/manpowerrequests" primaryText="All" leftIcon={<ManpowerRequests.icon />} />
          <MenuItemLink
            to={`/manpowerrequests?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<ManpowerRequests.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/manpowerrequests?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<ManpowerRequests.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menuProjectrequests')}
          isOpen={this.state.menuProjectrequests}
          sidebarIsOpen={open}
          name="Project Requests   &#x25BA;"
          icon={<Vendors.icon />}
        >
          <MenuItemLink to="/projectrequests" primaryText="All" leftIcon={<ProjectRequests.icon />} />
          <MenuItemLink
            to={`/projectrequests?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<ProjectRequests.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/projectrequests?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<ProjectRequests.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menurentalrequests')}
          isOpen={this.state.menurentalrequests}
          sidebarIsOpen={open}
          name="Rental Requests   &#x25BA;"
          icon={<Vendors.icon />}
        >
          <MenuItemLink to="/rentalrequests" primaryText="All" leftIcon={<RentalRequests.icon />} />
          <MenuItemLink
            to={`/rentalrequests?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<RentalRequests.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/rentalrequests?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<RentalRequests.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => this.handleToggle('menutrainingrequests')}
          isOpen={this.state.menutrainingrequests}
          sidebarIsOpen={open}
          name="Training Requests   &#x25BA;"
          icon={<Vendors.icon />}
        >
          <MenuItemLink to="/trainingrequests" primaryText="All" leftIcon={<TrainingRequests.icon />} />
          <MenuItemLink
            to={`/trainingrequests?filter={"createdAt":"week"}`}
            primaryText={`Weekly`}
            leftIcon={<Vendors.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/trainingrequests?filter={"createdAt":"month"}`}
            primaryText={`Monthly`}
            leftIcon={<Vendors.icon />}
            onClick={onMenuClick}
          />
        </SubMenu>
        <MenuItemLink to="/tools" primaryText="Tools" leftIcon={<Tools.icon />} />
        <MenuItemLink to="/taxis" primaryText="Taxi" leftIcon={<Taxi.icon />} />
        <MenuItemLink to="/trucks" primaryText="Truck" leftIcon={<Truck.icon />} />
        <MenuItemLink to="/course" primaryText="Course" leftIcon={<Course.icon />} />
        <MenuItemLink to={`/servicerequests`} primaryText={`Service Requests`} leftIcon={<ServiceRequests.icon />} />
        <SubMenu
          handleToggle={() => this.handleToggle('menuProjectSettings')}
          isOpen={this.state.menuProjectSettings}
          sidebarIsOpen={open}
          name="Settings   &#x25BA;"
          icon={<SettingsIcon />}
        >
          <MenuItemLink to="/banner" primaryText="Banners" leftIcon={<CompanyDetails.icon />} />
          <MenuItemLink to="/companydetails" primaryText="Company Details" leftIcon={<CompanyDetails.icon />} />
          <MenuItemLink to="/service" primaryText="Services" leftIcon={<Service.icon />} />
          <MenuItemLink to="/serviceCategory" primaryText="Service Category" leftIcon={<ServiceCategory.icon />} />
          <MenuItemLink to="/Jobrequestradius" primaryText="Job Request Radius" leftIcon={<Jobrequestradius.icon />} />
          <MenuItemLink to="/notification" primaryText="Notifications" leftIcon={<Jobrequestradius.icon />} />
          <MenuItemLink
            to={`/projectspecifications`}
            primaryText={`Project Specifications`}
            leftIcon={<ProjectSpecifications.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink to={`/units`} primaryText={`Units`} leftIcon={<Units.icon />} onClick={onMenuClick} />
        </SubMenu>
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme
});

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(Menu);
