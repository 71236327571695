import React from 'react';
import 'react-phone-number-input/style.css';
import getVendors from '../../dataproviders/Vendors';
import {
  Create,
  FormTab,
  TabbedForm,
  //   TextField,
  TextInput,
  FormDataConsumer,
  //   AutocompleteInput,
  ReferenceInput,
  ImageField,
  ImageInput,
  SelectInput,
  DateInput, // Import DateInput
  required,
  CardActions
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);

const ToolCreate = ({classes, ...props}) => (
  <Create {...props} title="Create Tool" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="Taxi Details" path="">
        <ReferenceInput source="Vendors" label="Vendor Name" reference="vendors" validate={[required()]}>
          <SelectInput optionText="vendorName" />
        </ReferenceInput>
        <FormDataConsumer>
          {({formData}) => {
            console.log('formData--------->', formData);
            // if (formData.vendorId) {
            getVendors({id: formData.vendorId})
              .then(function (response) {
                // Check if response contains vendorName property
                console.log('response------>', response);
                if (response.data && response.data.vendorName) {
                  // Update formData with vendorName
                  formData.vendorName = response.data.vendorName;
                }
              })
              .catch(function (error) {
                console.error('Error fetching vendor details:', error);
              });
            // } else {
            //   console.error('Error fetching ------------------>:', formData);
            // }
          }}
        </FormDataConsumer>
        <TextInput autoFocus source="Type" label="Taxi Type" validate={required()} formClassName={classes.Type} />
        <TextInput
          autoFocus
          source="rcownername"
          label="RC Owner Name"
          validate={required()}
          formClassName={classes.rcownername}
        />
        <TextInput autoFocus source="badge" label="Badge Number" validate={required()} formClassName={classes.badge} />
        <TextInput autoFocus source="badgearea" label="Badge Area" formClassName={classes.badge} />
        <TextInput
          autoFocus
          source="insurancenumber"
          label="Insurance Number"
          validate={required()}
          formClassName={classes.badge}
        />
        <DateInput // Change TextInput to DateInput
          autoFocus
          source="insurancedate"
          label="Insurance Expiry Date"
          validate={required()}
          formClassName={classes.badge}
        />
        <DateInput
          autoFocus
          source="taxvalidity"
          label="Tax Validity"
          validate={required()}
          formClassName={classes.badge}
        />
        <TextInput
          autoFocus
          source="capacity"
          label="Passenger Capacity"
          validate={required()}
          formClassName={classes.capacity}
        />
        <TextInput
          autoFocus
          source="platenumber"
          label="Register Number"
          validate={required()}
          formClassName={classes.platenumber}
        />
        <TextInput
          autoFocus
          source="servicecharge"
          label="Service Charge (per Kilometer) "
          validate={required()}
          formClassName={classes.servicecharge}
        />
        <TextInput
          autoFocus
          source="description"
          label="Description"
          validate={required()}
          formClassName={classes.rent}
        />
      </FormTab>
      <FormTab label="Images">
        <ImageField source="imagePath" title="imagePath" />
        {/* <ImageField source="imagePath" src="imagePath" label="Service image" /> */}
        <ImageInput
          source="images"
          label="images"
          accept="image/*"
          maxSize={5000000}
          placeholder={
            <p>
              Upload Image
              <span>*File size should not exceed 5MB</span>
            </p>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(ToolCreate);
