import React from 'react';
import 'react-phone-number-input/style.css';

import {Create, FormTab, TabbedForm, TextInput, required, CardActions} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const ManpowerRequestsCreate = ({classes, ...props}) => (
  <Create {...props} title="Create  Manpower Requests" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="" path="">
        <TextInput source="address" label="Address" validate={required()} formClassName={classes.address} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(ManpowerRequestsCreate);
