import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  EmailField,
  EditButton
  //   TextInput
  //   Filter,
} from 'react-admin';

// const CompanyFilter = (props) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="q" alwaysOn />
//   </Filter>
// );

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

const ShowEditButton = ({record, basePath}) => {
  return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
};

const CompanyBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const CompanyList = (props) => (
  <List title=" CompanyDetails" {...props} bulkActionButtons={<CompanyBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="id" disabled />
      <TextField source="companyName" />
      <TextField source="address" />
      <EmailField source="email" />
      <TextField source="phone" />

      <ShowEditButton />
    </Datagrid>
  </List>
);
export default CompanyList;
