import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //EmailField,
  //EditButton,
  Filter,
  TextInput,
  DateInput
} from 'react-admin';

const TrainingRequestsFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <DateInput source="startDate" alwaysOn />
    <DateInput source="endDate" alwaysOn />
    <TextInput label="Customer Name" source="customerName" alwaysOn />
    <TextInput label="Service Name" source="serviceName" alwaysOn />
    <TextInput label="Mobile" source="mobileNumber" alwaysOn />
    <TextInput label="Course Name" source="courseName" alwaysOn />
    <TextInput label="Duration" source="duration" />
    <TextInput label="Qualification" source="qualification" />
    <TextInput label="Address" source="address" />
    <TextInput label="LocalBody" source="localBody" />
    <TextInput label="District" source="district" />
    <TextInput label="State" source="state" />
    <TextInput label="Pincode" source="pincode" />
    <TextInput label="AmountToBePaid" source="amountToBePaid" />
    <TextInput label="AmountPaid" source="amountPaid" />
    <TextInput label="BalanceAmount" source="balanceAmount" />
    <TextInput label="CreatedAt" source="createdAt" />
    <TextInput label="Approved Vendor" source="approvedVendorId" />
    <TextInput label="Status" source="status" alwaysOn />
  </Filter>
);

const rowClick = (id, basePath, record) => {
  if (record.id === 0) {
    return 'show';
  }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const TrainingRequestsBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

export const TrainingRequestsList = (props) => (
  <List
    title=" Training Requests"
    {...props}
    filters={<TrainingRequestsFilter />}
    bulkActionButtons={<TrainingRequestsBulkActionButtons />}
  >
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="customerName" label="Customer Name" />
      <TextField source="mobileNumber" label="Mobile" />
      <TextField source="courseName" label="Course Name" />
      <TextField source="qualification" />
      <TextField source="address" />
      <TextField source="localBody" />
      <TextField source="district" />
      <TextField source="state" />
      <TextField source="pincode" />
      <TextField source="amountToBePaid" label="Total Amount" />
      <TextField source="amountPaid" label="Amount Paid" />
      <TextField source="balanceAmount" label="Balance Amount" />
      <TextField source="duration" />
      <TextField source="durationPeriod" label="Duration Period" />
      <TextField source="approvedVendorId" />
      <TextField source="status" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
export default TrainingRequestsList;
