import {ShowController} from 'ra-core';
import React from 'react';
import {ShowView, Tab, TabbedShowLayout, TextField} from 'react-admin';

const TrainingRequestsDetailsShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="">
            <TextField source="customerName" />
            <TextField source="courseName" />
            <TextField source="qualification" />
            <TextField source="mobileNumber" />
            <TextField source="emailId" />
            <TextField source="description" />
            <TextField source="noOfHours" />
            <TextField source="address" />
            <TextField source="localBody" />
            <TextField source="district" />
            <TextField source="state" />
            <TextField source="approvedVendorId" />
            <TextField source="amountToBePaid" />
            <TextField source="amountPaid" />
            <TextField source="balanceAmount" />
            <TextField source="duration" />
            <TextField source="durationPeriod" />
            <TextField source="status" />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default TrainingRequestsDetailsShow;
