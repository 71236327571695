import React from 'react';
import {
  List,
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  //   EditButton,
  Filter,
  TextInput,
  ImageField
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const TaxiFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    <TextInput source="vendorId" label="Vendor Id" alwaysOn />
    <TextInput source="Type" label="Taxi Type" alwaysOn />
    <TextInput source="rcownername" label="RC Owner Name" alwaysOn />
    <TextInput source="platenumber" label="Register Number" alwaysOn />
    <TextInput source="servicecharge" label="Service Charge (per Kilometer)" />
  </Filter>
);

const rowClick = () => {
  // if (record.id === 0) {
  //   return 'show';
  // }
  return 'edit';
};

// const ShowEditButton = ({record, basePath, resource}) => {
//   return record.id !== 0 && <EditButton basePath={basePath} record={record} />;
// };

const TaxiBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

const styles = {
  image: {
    maxHeight: '50px',
    maxWidth: '50px',
    borderRadius: '30%'
  }
};
export const TaxiList = ({classes, ...props}) => (
  <List title=" Taxi" {...props} filters={<TaxiFilter />} bulkActionButtons={<TaxiBulkActionButtons />}>
    <Datagrid rowClick={rowClick} /*expand={<UserEdit/> }*/ {...props}>
      <TextField source="vendorId" label="Vendor Id" />
      <TextField source="Type" label="Taxi Type" />
      <TextField source="rcownername" label="RC Owner Name" />
      <TextField source="platenumber" label="Register Number" />
      <TextField source="servicecharge" label="Service Charge (per Kilometer)" />
      <TextField source="description" label="Description" />
      <ImageField source="toolImagePath" label="photo" classes={classes} />
    </Datagrid>
  </List>
);

export default withStyles(styles)(TaxiList);
