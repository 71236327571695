import React from 'react';
import 'react-phone-number-input/style.css';

import {Create, FormTab, TabbedForm, TextInput, email, required, CardActions} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import BackButton from '../common/BackButton';

export const styles = {
  name: {display: 'inline-block'},
  email: {width: 544},
  phone: {width: 544}
};

const ListActions = () => (
  <CardActions>
    <BackButton />
  </CardActions>
);
const CompanyCreate = ({classes, ...props}) => (
  <Create {...props} title="Create  Company" actions={<ListActions />}>
    <TabbedForm>
      <FormTab label="" path="">
        <TextInput
          autoFocus
          source="companyName"
          label="Company Name"
          validate={required()}
          formClassName={classes.companyName}
        />
        <TextInput source="address" label="Address" validate={required()} formClassName={classes.address} />
        <TextInput source="localBody" label="Local body" validate={required()} formClassName={classes.localBody} />
        <TextInput source="district" label="District" validate={required()} formClassName={classes.district} />
        <TextInput source="state" label="State" validate={required()} formClassName={classes.state} />
        <TextInput source="pincode" label="Pincode" validate={required()} formClassName={classes.pincode} />
        <TextInput type="tel" source="phone" validate={required()} formClassName={classes.email} />
        <TextInput type="tel" source="alt_phone" label="Alternate Phone Number" formClassName={classes.email} />
        <TextInput
          type="tel"
          source="whatsappNumber"
          label="WhatsApp Number"
          validate={required()}
          formClassName={classes.whatsappNumber}
        />
        <TextInput
          type="email"
          source="email"
          validate={[required(), email()]}
          fullWidth={true}
          formClassName={classes.email}
        />
        <TextInput
          type="email"
          source="alt_email"
          label="Alternate Email"
          validate={[email()]}
          fullWidth={true}
          formClassName={classes.email}
        />
        <TextInput source="website" label="Website" validate={required()} formClassName={classes.website} />
        <TextInput
          source="profileDescription"
          label="Profile Description"
          validate={required()}
          fullWidth={true}
          formClassName={classes.profileDescription}
          multiline
        />
        <TextInput
          source="aboutUs"
          label="About Us"
          validate={required()}
          fullWidth={true}
          formClassName={classes.aboutUs}
          multiline
        />
        <TextInput
          source="mission"
          label="Mission"
          validate={required()}
          fullWidth={true}
          formClassName={classes.mission}
          multiline
        />
        <TextInput
          source="vision"
          label="Vision"
          validate={required()}
          fullWidth={true}
          formClassName={classes.vision}
          multiline
        />
        <TextInput
          source="goal"
          label="Goal"
          validate={required()}
          fullWidth={true}
          formClassName={classes.visgoalion}
          multiline
        />
        <TextInput source="facebookLink" label="Facebook Link" fullWidth={true} formClassName={classes.facebookLink} />
        <TextInput source="twitterLink" label="Twitter Link" fullWidth={true} formClassName={classes.twitterLink} />
        <TextInput
          source="instagramLink"
          label="Instagram Link"
          fullWidth={true}
          formClassName={classes.instagramLink}
        />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(CompanyCreate);
